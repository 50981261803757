import React from "react";
import './css/customButton.css';

function CustomButton(props) {
  return (
    <button
      type="button"
      className={`btn button-b5 button-5 ${props.className ? props.className : ''}`}
      onClick={props.onClick}
      disabled={props.disabled ? true : false}
    >
      {props.loading ? <i class="fa-solid fa-spinner fa-spin fa-fw mr-2"></i>  : <></>}
      {props.text ? props.text : ''}
    </button>
  )
}

export default CustomButton;