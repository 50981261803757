import React, { useEffect, useState } from 'react';


function PasswordText(props) {
    const [value, setValue] = useState(props.value ? props.value : "");
    const [invalid, setInvalid] = useState(false);
    const [edited, setEdited] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useEffect(() => {
        if (!props.disabled) {
            if (props.required) {
                if (edited || props.submited) {
                    if (!value) {
                        setInvalid(true);
                    }
                    else {
                        setInvalid(false);
                    }
                }
                else {
                    setInvalid(false);
                }
                setEdited(false);
            }
        }
        else {
            setInvalid(false);
        }
    }, [value, props.required, edited, props.submited, props.disabled]);

    const onChange = (e) => {
        props.onChange && props.onChange(e);
        setValue(e.target.value);
        setEdited(true);
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    return (
        <>
            <div className=' mb-3'>

                <div className="input-group">
                    <input
                        type={showPassword ? "text" : "password"}
                        id={props.id}
                        name={props.name}
                        ref={props.ref}
                        value={value}
                        className={`${props.PasswordTextClasses ? props.PasswordTextClasses : ""} ${invalid && "signup-input-invalid"}`}
                        placeholder={`${props.placeholder ? props.placeholder : ""}`}
                        onChange={(e) => onChange(e)}
                        disabled={props.disabled === true}
                        formNoValidate={true}
                        data-form={props.form ? props.form : ""}
                        data-invalid={invalid}
                        data-required={props.required ? props.required : false}
                        style={{ flex: '1 1 0%' }}
                        autocomplete="off"
                    />
                    <span className="input-group-text">
                        <i
                            className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"} password-toggle-icon`}
                            onClick={togglePasswordVisibility}
                        ></i>
                    </span>
                </div>
                {
                    invalid && <span className="error-label">
                        <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                        {props.errorMessage ? props.errorMessage : "This field is required"}
                    </span>
                }
            </div>
        </>
    );
}

export default PasswordText;