import * as React from 'react';

import './css/customRadio.css';

function YesNoRadio(props) {
    return (
        <>
            <label class="custom-switch-label pr-2" for="">{props.noText ? props.noText : "No"}</label>
            <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" name={props.name} id={props.name}
                    onClick={(e) => props.onSelectionChanged(e, props.value === 'Y' ? 'N' : 'Y')}
                    checked={props.value === 'Y'}
                />
                <label class="custom-control-label custom-switch-label" for={props.name}>{props.yesText ? props.yesText : "Yes"}</label>
            </div>
        </>
    );
}

export default YesNoRadio;