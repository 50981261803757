import React from "react";
import './css/sectionDemo.css';
import EmailSubscribe from "../emailSubscribe/emailSubscribe";

function SectionDemo(props) {
  return (
    <div className="demo row align-content-center ml-1 mr-1 ml-md-5 mr-md-5">
      <div className="col-lg-6 p-5">
        <h1 className="sectionDemo-title px-md-5">
          Get a <span className="sectionDemo-title-bold">demo</span> of Hirebase Application
        </h1>
      </div>
      <div className="col-lg-6 py-5 p-md-5">
        <div className="d-flex justify-content-center pt-lg-5">
          <EmailSubscribe onClick={props.openModalSignUp}/>
        </div>
      </div>
    </div>
  )
}

export default SectionDemo;