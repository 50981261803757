import React, { useState } from "react";
import './footer.css';
import { useHistory } from "react-router";
import { AnimationOnScroll } from "react-animation-on-scroll";

import Logo from "../logo/logo";
// import logoImg from '../../assets/img/logo/Hirebase-logo-dark-light.png';
import logoImg from '../../assets/img/logo/hirebase-logo-dark-light.svg';

import twitterImg from "../../assets/img/clipart/twitter.svg";
import linkedinImg from "../../assets/img/clipart/linkedin.svg";
import facebookImg from "../../assets/img/clipart/facebook.svg";

import twitterImg2 from "../../assets/img/clipart/twitter-2.svg";
import linkedinImg2 from "../../assets/img/clipart/linkedin-2.svg";
import facebookImg2 from "../../assets/img/clipart/facebook-2.svg";

import ReactGA from 'react-ga4';

function Footer() {
  const [twitterImage, setTwitterImage] = useState(twitterImg);
  const [linkedinImage, setLinkedinImage] = useState(linkedinImg);
  const [facebookImage, setFacebookImage] = useState(facebookImg);

  const history = useHistory();

  const onPrivacyPolicy = (e) => {
    history.push('/privacy-policy');
    //GA4 Tracking
    ReactGA.event({
      category: 'Privacy Policy',
      action: 'Privacy Policy',
      label: 'Privacy Policy'
    });
  }

  const onAboutUs = (e) => {
    history.push('/about-us');
    //GA4 Tracking
    ReactGA.event({
      category: 'About Us',
      action: 'About Us',
      label: 'About Us'
    });
  }

  const onContactUs = (e) => {
    history.push('/contact-us');
    //GA4 Tracking
    ReactGA.event({
      category: 'Contact Us',
      action: 'Contact Us',
      label: 'Contact Us'
    });
  }

  const onClickTwitter = (e) => {
    //GA4 Tracking
    ReactGA.event({
      category: 'Social Network',
      action: 'Twitter',
      label: 'Twitter'
    });
  }

  const onClickLinkedin = (e) => {
    //GA4 Tracking 
    ReactGA.event({
      category: 'Social Network',
      action: 'Linkedin',
      label: 'Linkedin'
    });
  }

  const onClickFacebook = (e) => {
    //GA4 Tracking
    ReactGA.event({
      category: 'Social Network',
      action: 'Facebook',
      label: 'Facebook'
    });
  }

  return (
    <>
      <footer className="row page-container footer-section">
        <div className="container">
          <div className="footer-content">
            <div className="row  pb-4 custom-row">
              <div className="col-lg-4 col-md-12 mb-50 px-md-5">
                <div className="footer-widget">
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    <div className="footer-logo">
                      <Logo src={logoImg} />
                    </div>
                    <div className="footer-text">
                      <p>Navya Ventures LLC</p>
                      <a class="phone-link" href="tel:4242449076">(424)244-9076</a>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
              <div className="col-lg-4 col-md-12  mb-30 px-md-5">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <div className="footer-widget">
                    <ul>
                      <li><a onClick={onAboutUs}>About Us</a></li>
                      <li><a onClick={onPrivacyPolicy}>Privacy Policy</a></li>
                      <li><a onClick={onContactUs}>Contact Us</a></li>
                    </ul>
                  </div>
                </AnimationOnScroll>
              </div>
              <div className="col-lg-4 col-md-8 mb-50 px-md-5">
                <div className="footer-widget mb-4 mb-md-0">
                  <AnimationOnScroll animateIn="animate__zoomIn">
                    <div className="footer-widget-heading">
                      <h3>To know more about us</h3>
                    </div>
                    <div className="footer-social-icon pt-md-4">
                      <a href="https://twitter.com/base_hire"
                        target="_balcnk"
                        onMouseEnter={() => setTwitterImage(twitterImg2)}
                        onMouseLeave={() => setTwitterImage(twitterImg)}
                        onClick={onClickTwitter}
                      >
                        <img src={twitterImage} alt="no" />
                      </a>
                      <a href="https://www.linkedin.com/company/hirebase-co/about/"
                        target="_balcnk"
                        onMouseEnter={() => setLinkedinImage(linkedinImg2)}
                        onMouseLeave={() => setLinkedinImage(linkedinImg)}
                        onClick={onClickLinkedin}
                      >
                        <img src={linkedinImage} alt="no" />
                      </a>
                      <a href="https://www.facebook.com/gethirebase"
                        target="_balcnk"
                        onMouseEnter={() => setFacebookImage(facebookImg2)}
                        onMouseLeave={() => setFacebookImage(facebookImg)}
                        onClick={onClickFacebook}
                      >
                        <img src={facebookImage} alt="no" />
                      </a>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </div>
        </div>

      </footer>
      <div className="copyright-area">
        <div className="container">
          <div className="d-flex justify-content-center py-1">
            <div className="copyright-text">
              &copy; 2022 Hirebase. All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;