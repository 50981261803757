export const isFormValid = (formName) => {
    var controls = document.querySelectorAll(`[data-form='${formName}']`);

    console.log('isFormValid--------->')
    console.log('controls--------->', controls)

    for (let i = 0; i < controls.length; i++) {
        let control = controls[i];
        if (control.tagName.toLowerCase() === "div") {
            if (control.dataset.invalid === "true") {
                //console.log(control);
                return false;
            }
        }
        else {
            if (!control.disabled && (control.dataset.invalid === "true"
                || (control.dataset.required === "true" && control.value === ""))) {
                //console.log(control);
                return false;
            }
        }

    }
    return true;
}