import React, { useCallback, useEffect, useState } from "react";
// import { useHistory } from "react-router";
import CustomButton from "../../controls/buttons/customButton";
import './subscription.css';
import laptopImg from '../../../assets/img/subscription/laptop.png'

import { useDispatch, useSelector } from "react-redux";
import { createCompany, createNewCompany, getCheckSubDomainIsTaken, getVerifyTrialAccountLink, selectCheckSubDomainIsTaken, selectCheckSubDomainIsTakenStatus, selectCompanyById, selectCompnayCreationStatus, selectVerifyTrialAccountLinkData, selectVerifyTrialAccountLinkError, selectVerifyTrialAccountLinkStatus, updateSingleData } from "./reducerSlices/subscriptionSlice";
import SubDomainUrlText from "../../controls/textbox/subDomainUrlText";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { fetchStatus } from "../../../api/client";
import InputGroup from "../../controls/inputGroup/inputGroup";
import ShortText from "../../controls/textbox/shortText";
import { onValueChanged, sections } from "./services/companySaver";
import PhoneText from "../../controls/textbox/phoneText";
import UrlText from "../../controls/textbox/urlText";
import { isFormValid } from "../../controls/services/formService";
import EmailText from "../../controls/textbox/emailText";
import PasswordText from "../../controls/textbox/passwordText";



function Subscription(props) {

  const dispatch = useDispatch();
  // const history = useHistory();
  const location = useLocation();

  const COMPANY_FORM = "CompanyForm";

  const [cfSubmitted, setCFSubmitted] = useState(false);

  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [passwordLengthInvalid, setPasswordLengthInvalid] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const company = useSelector(selectCompanyById);
  const verifyTrialAccountLinkData = useSelector(selectVerifyTrialAccountLinkData);
  const verifyTrialAccountLinkStatus = useSelector(selectVerifyTrialAccountLinkStatus);
  const verifyTrialAccountLinkError = useSelector(selectVerifyTrialAccountLinkError);

  const checkSubDomainIsTakenStatus = useSelector(selectCheckSubDomainIsTakenStatus);
  const checkSubDomainIsTaken = useSelector(selectCheckSubDomainIsTaken);

  const compnayCreationStatus = useSelector(selectCompnayCreationStatus);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const code = params.get('code');

    dispatch(createNewCompany({ subscriptionId: id }));

    if (id && code) {
      verifyLink(id, code);
    }
  }, [location]);

  const verifyLink = async (id, code) => {
    await dispatch(getVerifyTrialAccountLink({
      id: id,
      code: code
    }));
  }


  const _getCheckSubDomainIsTaken = async (workspaceURL) => {
    await dispatch(getCheckSubDomainIsTaken({
      workspaceURL: workspaceURL
    }));
  }

  // Debounced function
  const debouncedCheckSubDomainIsTaken = useCallback(
    (() => {
      let timeout;
      return async (newDomain) => {
        if (timeout) clearTimeout(timeout);

        timeout = setTimeout(async () => {
          await _getCheckSubDomainIsTaken(newDomain);
        }, 300); // Adjust the debounce delay as needed
      };
    })(),
    []
  );


  const customValidation = () => {
    if (checkSubDomainIsTaken) {
      if (checkSubDomainIsTaken.isTaken) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  const customPasswordValidation = () => {
    if (password.length >= 8) {
      setPasswordLengthInvalid(false);
      if (password === confirmPassword) {
        setPasswordMismatch(false);
        return true;
      } else {
        setPasswordMismatch(true);
        return false;
      }
    } else {
      setPasswordLengthInvalid(true);
      return false;
    }
  }

  const onSave = () => {


    if (customValidation() && isFormValid(COMPANY_FORM) && customPasswordValidation()) {
      console.log('onSave --------> if')
      if (company) {

        dispatch(createCompany({
          // client: tenantId,
          // serviceType: "TENANTADMIN",
          // operation: "SAVESETTINGCOMPANYMASTER",

          payload: {
            id: "",
            data: JSON.stringify({
              settingCompanyMaster: JSON.stringify(company),
            })
          }
          // settingCompanyMaster: JSON.stringify(company),
          // data : JSON.stringify({settingCompanyMaster: JSON.stringify(company)})
        }));

      }
      // setCreateMode(false);
      // setEditmode(false);
      // setCFInvalid(false);
      setCFSubmitted(false);
    }
    else {
      console.log('onSave --------> else')
      // setCFInvalid(true);
    }
    setCFSubmitted(true);
  }

  async function onCreateAccount(e) {
    console.log('company ->', company);
    onSave();
  }













  // useEffect(() => {
  //   dispatch(createNewCompany());
  //   console.log('createNewCompany --------------')
  // }, []);

  const onValueUpdated = (e, section, value) => {
    let _companyObj = Object.assign({}, company);
    let _newCompanyObj = onValueChanged(_companyObj, e, section, value);
    dispatch(updateSingleData({
      company: _newCompanyObj
    }));
  };


  return (
    <>

      <div className="container justify-content-center col-md-9 bg-light-gray p-5 h-100 vh-100">

        <div className="card card-wizard overflow-hidden">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4 pr-0 d-none d-md-flex">
                <img src={laptopImg} className="w-100 h-100" />
              </div>
              <div className="col-md-8">
                <div className="row py-5 custom-row" >
                  <div className="col-md-12">
                    <h1 className="collaborate-title"> Getting Started with <span className="collaborate-title-underline">Company</span> Details</h1>
                    {/* <h1 className="card-wizard-header"> Getting Started with <span className="collaborate-title-underline">Company</span> Details</h1> */}
                    <div className="card-wizard-description pt-2">Please fill in the details below to <b>register your company</b>.</div>
                    <div className="card-wizard-description">These details will be used for your application, to create your company profile, and to set up your <b>workspace domain</b>.</div>

                  </div>
                </div>
                <div className="row py-5 custom-row" >

                  {(verifyTrialAccountLinkStatus !== fetchStatus.LOADING) &&
                    verifyTrialAccountLinkError !== "" && verifyTrialAccountLinkError !== null ? (
                    <>
                      <div className="col-md-12  mt-5 p-md-5 pt-3">
                        <div className="d-flex justify-content-center">
                          <h3 className="text-danger">
                            {verifyTrialAccountLinkError}
                          </h3>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {compnayCreationStatus === fetchStatus.SUCCEEDED ?
                        <>
                          <div className="col-md-12  mt-5 p-md-5 pt-5">
                            <div className="d-flex justify-content-center">
                              <h3 className="text-success">
                                Thank you for registering with us. We’ve received your details and will review them shortly. Our team will reach out to you within the next 48 hours.
                              </h3>
                            </div>
                          </div>
                        </>
                        :
                        <>
                          {verifyTrialAccountLinkStatus === fetchStatus.SUCCEEDED ?

                            <>

                              <div className="col-md-6">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblCompanyName" className="field-label" htmlFor="txtCompanyName">Company Name</label>
                                  <InputGroup
                                    icon={<i className="fa fa-address-card"></i>}
                                    control={
                                      <ShortText
                                        id="txtCompanyName"
                                        name="txtCompanyName"
                                        ShortTextClasses="signup-input"
                                        placeholder="Company Name"
                                        value={company && company.CompanyName ? company.CompanyName.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                                        required={true}
                                        errorMessage="Enter Company Name"
                                        form={COMPANY_FORM}
                                        submited={cfSubmitted} />
                                    } />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblCompanyPhoneNumber" className="field-label" htmlFor="txtCompanyPhoneNumber">Company Phone Number</label>
                                  <InputGroup
                                    icon={<i className="fa fa-address-card"></i>}
                                    control={
                                      <PhoneText
                                        id="txtCompanyPhoneNumber"
                                        name="txtCompanyPhoneNumber"
                                        PhoneTextClasses="signup-input"
                                        placeholder="(000)-00-0000"
                                        value={company && company.CompanyPhoneNumber ? company.CompanyPhoneNumber.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                                        required={true}
                                        errorMessage="Enter Phone Number"
                                        form={COMPANY_FORM}
                                        submited={cfSubmitted}
                                      />
                                    } />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblCompanyAddress" className="field-label" htmlFor="txtCompanyAddress">Company Address</label>
                                  <InputGroup
                                    icon={<i className="fa fa-address-card"></i>}
                                    control={
                                      <ShortText
                                        id="txtCompanyAddress"
                                        name="txtCompanyAddress"
                                        ShortTextClasses="signup-input"
                                        placeholder="Company Address"
                                        value={company && company.CompanyAddress ? company.CompanyAddress.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                                        required={true}
                                        errorMessage="Enter Company Address"
                                        form={COMPANY_FORM}
                                        submited={cfSubmitted} />
                                    } />
                                </div>
                              </div>


                              <div className="col-md-6">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblCompanyWebsite" className="field-label" htmlFor="txtCompanyWebsite">Company Website</label>
                                  <InputGroup
                                    icon={<i className="fa fa-address-card"></i>}
                                    control={
                                      <UrlText
                                        id="txtCompanyWebsite"
                                        name="txtCompanyWebsite"
                                        UrlTextClasses="signup-input"
                                        placeholder="Company Website"
                                        value={company && company.CompanyWebsite ? company.CompanyWebsite.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                                        // required={companyForm && companyForm.CompanyWebsite.Required}
                                        errorMessage="Enter Company Website"
                                        form={COMPANY_FORM}
                                        submited={cfSubmitted} />
                                    } />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblWorkspaceURL" className="field-label" htmlFor="txtWorkspaceURL">Workspace URL</label>
                                  <InputGroup
                                    icon={<i className="fa fa-address-card"></i>}
                                    control={
                                      <>
                                        <SubDomainUrlText
                                          id="txtWorkspaceURL"
                                          name="txtWorkspaceURL"
                                          subDomainUrlTextClasses="signup-input"
                                          placeholder="Workspace Name"
                                          value={company && company.WorkspaceURL ? company.WorkspaceURL.Data : ""}
                                          onChange={(e) => { onValueUpdated(e, sections.COMPANY_DETAILS, null); debouncedCheckSubDomainIsTaken(e.target.value) }}
                                          required={true}
                                          // errorMessage={companyForm && companyForm.CompanyWebsite.Validation}
                                          form={COMPANY_FORM}
                                          submited={cfSubmitted}
                                        >
                                          {checkSubDomainIsTaken && checkSubDomainIsTaken.isTaken &&
                                            <span className="error-label">
                                              <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                              {'Workspace URL is already taken.'}
                                            </span>
                                          }
                                        </SubDomainUrlText>
                                      </>
                                    } />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label className="field-label" htmlFor="txtEmail">Email Id</label>
                                  <InputGroup
                                    icon={<i className="fa fa-map-marker"></i>}
                                    control={
                                      <EmailText
                                        id="txtEmail"
                                        name="txtEmail"
                                        placeholder="Enter Email Address"
                                        EmailTextClasses="signup-input"
                                        value={company && company.Email ? company.Email.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                                        required={true}
                                        errorMessage="Enter Email Address"
                                        form={COMPANY_FORM}
                                        submited={cfSubmitted} />
                                    } />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblFirstName" className="field-label" htmlFor="txtFirstName">First Name</label>
                                  <InputGroup
                                    icon={<i className="fa fa-address-card"></i>}
                                    control={
                                      <ShortText
                                        id="txtFirstName"
                                        name="txtFirstName"
                                        ShortTextClasses="signup-input"
                                        placeholder="First Name"
                                        value={company && company.FirstName ? company.FirstName.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                                        required={true}
                                        errorMessage="Enter First Name"
                                        form={COMPANY_FORM}
                                        submited={cfSubmitted} />
                                    } />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblLastName" className="field-label" htmlFor="txtLastName">Last Name</label>
                                  <InputGroup
                                    icon={<i className="fa fa-address-card"></i>}
                                    control={
                                      <ShortText
                                        id="txtLastName"
                                        name="txtLastName"
                                        ShortTextClasses="signup-input"
                                        placeholder="Last Name"
                                        value={company && company.LastName ? company.LastName.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                                        required={true}
                                        errorMessage="Enter Last Name"
                                        form={COMPANY_FORM}
                                        submited={cfSubmitted} />
                                    } />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <label id="lblPassword" className="field-label" htmlFor="txtPassword">Password</label>
                                <InputGroup
                                  icon={<i className="fa fa-unlock-alt"></i>}
                                  control={
                                    <PasswordText id="txtPassword"
                                      name="txtPassword"
                                      PasswordTextClasses="signup-input "
                                      placeholder="Enter Password"
                                      value={password}
                                      onChange={(e) => { setPassword(e.target.value); onValueUpdated(e, sections.COMPANY_DETAILS, null); }}
                                      required={true}
                                      errorMessage="Please enter password"
                                      form={COMPANY_FORM}
                                      submited={cfSubmitted} />
                                  } />
                                {passwordLengthInvalid && <span className="error-label">
                                  <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                  {"Password should be at least 8 characters"}
                                </span>}
                              </div>

                              <div className="col-md-6">
                                <label className="field-label" htmlFor="txtConfirmPassword">Confirm Password</label>
                                <InputGroup
                                  icon={<i className="fa fa-unlock-alt"></i>}
                                  control={
                                    <PasswordText
                                      id="txtConfirmPassword"
                                      name="txtConfirmPassword"
                                      PasswordTextClasses="signup-input"
                                      placeholder="Confirm Password"
                                      value={confirmPassword}
                                      onChange={(e) => { setConfirmPassword(e.target.value); onValueUpdated(e, sections.COMPANY_DETAILS, null); }}
                                      required={true}
                                      errorMessage="Enter Confirm Password"
                                      form={COMPANY_FORM}
                                      submited={cfSubmitted} />
                                  } />
                                {passwordMismatch && <span className="error-label">
                                  <i className="fa fa-exclamation-circle mr-1" aria-hidden="true"></i>
                                  {"Password and Confirm Password did not match"}
                                </span>}
                              </div>



                              <div className="col-md-6">
                                <div className="form-group p-0 mobile-margin-bottom-3">
                                  <label id="lblLocationName" className="field-label" htmlFor="txtLocationName">Location Name</label>
                                  <InputGroup
                                    icon={<i className="fa fa-address-card"></i>}
                                    control={
                                      <ShortText
                                        id="txtLocationName"
                                        name="txtLocationName"
                                        ShortTextClasses="signup-input"
                                        placeholder="Location Name"
                                        value={company && company.LocationName ? company.LocationName.Data : ""}
                                        onChange={(e) => onValueUpdated(e, sections.COMPANY_DETAILS, null)}
                                        required={true}
                                        errorMessage="Enter Location Name"
                                        form={COMPANY_FORM}
                                        submited={cfSubmitted} />
                                    } />
                                </div>
                              </div>

                              <div className="col-md-12 mt-2">
                                {/* <CustomButton className="signup-button" text="Setup Company" /> */}
                                <CustomButton
                                  className="signup-button"
                                  text="Setup Company"
                                  disabled={compnayCreationStatus === fetchStatus.LOADING}
                                  loading={compnayCreationStatus === fetchStatus.LOADING}
                                  onClick={onCreateAccount}
                                />
                              </div>
                            </>
                            :
                            <></>
                          }
                        </>

                      }

                    </>
                  )
                  }
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default Subscription