import React, { useState } from "react";

import './css/section2.css';
import dubleLineShapeImg from '../../assets/img/clipart/duble-line-shape.png';
import curveLineUpImg from '../../assets/img/clipart/curve-line-up.png';
import WizardEN from '../../assets/img/page/Wizard-without-margin.gif';
import WizardSP from '../../assets/img/page/wizard-without-margin-spanish.gif';

import { AnimationOnScroll } from 'react-animation-on-scroll';
import hirebaseVideo from '../../assets/video/hirebase.mp4';
import YesNoRadio from "../controls/checkers/yesNoRadio";

function Section2() {

  const [isEnglish, setIsEnglish] = useState("Y");

  return (
    <div className="row rounded bg-light-gray custom-row">
      <div className="col-lg-6 p-md-5">
        <AnimationOnScroll animateIn="animate__fadeInLeft">
          <h1 className="section2-title section2-title">Register Leads and Employees <span className="section2-title-bold section2-title-underline">Directly</span> On your <span className="section2-title-bold">website</span></h1>
          <div className="float-right">
          </div>
          <img src={dubleLineShapeImg} alt="no" style={{ width: "78%" }} />
          <ul className="section2-ul">
            <li>File and generate new hire paperwork</li>
            <li>Accept applications directly from your website</li>
            <li>Offer any online training / videos quizzes</li>
            <li>Prefill I9 and W2 forms </li>
          </ul>
        </AnimationOnScroll>
      </div>
      <div className="col-lg-6 p-md-5">
        <div className="d-flex justify-content-center pb-3">
          <YesNoRadio
            name="rdbtnLang"
            yesText={`English`}
            noText={`Spanish`}
            onSelectionChanged={(e, v) => setIsEnglish(isEnglish == "Y" ? "N" : "Y")}
            value={isEnglish === "Y" ? "Y" : "N"} />
        </div>
        {/* <div className="row">
          {isEnglish === "Y" ?
            <img className="shape shape-one" src={WizardEN} alt="Home" height="434px" />
            :
            <img className="shape shape-one" src={WizardSP} alt="Home" height="434px" />
          }
        </div> */}
        <div className="row">
          <img className="shape shape-one" src={isEnglish === "Y" ? WizardEN : WizardSP} alt="Home" height="434px" />
        </div>
        {/* <div className="row pt-5">
          <div className="ml-auto">
            <div className="mr-0">
              <img src={curveLineUpImg} className="pl-5" alt="no" />
            </div>
          </div>
        </div> */}
      </div>
    </div >
  )
}

export default Section2;