import React, { useEffect } from "react";
import './onboarding.css';

import SectionDemo from "../section/sectionDemo";
import SectionFreeDemo from "../section/sectionFreeDemo";

import circleImg from './../../assets/img/clipart/circle.png'
import curveLineAarrowRoundVerticalImg from './../../assets/img/clipart/curve-line-arrow-round-vertical.svg';
import fallingStarImg from './../../assets/img/clipart/falling-star.svg';

import uploadDownloadDocumentsImg from './../../assets/img/home/upload-download-documents.png';
import documentSignatureImg from './../../assets/img/page/document-signature.png';
import formDetailsImg from './../../assets/img/page/form-details.png';

import ReactGA from 'react-ga4';

function Onboarding(props) {

  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });

  // useEffect(() => {
  //   //GA4 Tracking
  //   ReactGA.event({
  //     category: 'On Boarding',
  //     action: 'On Boarding',
  //     label: 'On Boarding'
  //   });
  // }, []);

  return (
    <>
      <div className="row page-container">
        <div className="container" >
          <div className="row custom-row">
            <div className="col-md-12  mt-5 pt-5 p-md-5 ">
              <h1 className="d-flex justify-content-center section1-title">
                Generate and Pre-Fill.
              </h1>
              <h1 className="d-flex justify-content-center section1-title">
                Everything.
              </h1>
              <div className="d-flex justify-content-center pt-3">
                <img className="d-md-block" src={fallingStarImg} alt="no" style={{ position: "absolute", marginTop: "0%", marginLeft: "44%" }} />
              </div>
              <img className="d-none d-lg-block" src={curveLineAarrowRoundVerticalImg} alt="no" style={{ position: "absolute", marginTop: "0%", marginLeft: "8%", height: "30%" }} />
              <div className="d-flex justify-content-center pt-5 onboarding-sub-heading"> Tired of dealing with multiple systems?  With hirebase you can generate forms With a single click </div>
            </div>
          </div>
          <div className="row custom-row onboarding-bg">
            <div className="col-md-7  mt-lg-5 p-md-5 ">
              <div className="d-flex justify-content-center pt-5">
                <img src={formDetailsImg} alt="Onboarding Photo" className="w-100" />
              </div>
            </div>
            <div className="col-md-5  mt-lg-5 p-md-5 align-self-center">
              <div className="d-flex justify-content-center pt-5">
                <img src={uploadDownloadDocumentsImg} alt="Onboarding Photo" className="w-75" />
              </div>
            </div>
          </div>

          <SectionFreeDemo
            title={<>See it to <span className="sectionFreeDemo-title-bold">belive</span> it. </>} 
            openModalSignUp={props.openModalSignUp}
          />

          <div className="row custom-row" >
            <div className="col-lg-6 p-md-5">
              <div className="d-flex justify-content-center">
                <img src={uploadDownloadDocumentsImg} className="w-75" />
              </div>
            </div>
            <div className="col-lg-6 p-5 align-self-center">
              <div className="section2-title">Streamline Your</div>
              <h1 className="section2-title"><span className="section1-title-bold">Workflow</span></h1>
              <h3 className="h5 pt-4">
                As soon as your employees are onboarded. Admins will be able to generate I9 , W4 , and EID forms.  Have a custom form that you would like to be filled ? No problem contact us and we will make it happen
              </h3>
            </div>
          </div>

          <img className="shape shape-three" src={circleImg} alt="no" style={{ position: "absolute", marginTop: "-2%", marginLeft: "12%" }} />

          <div className="row  bg-light-gray custom-row pt-5">
            <div className="col-lg-6 p-5 align-self-center">
              <h1 className="section1-title">Sign and <span className="section1-title-bold">Initial</span></h1>
              <h3 className="h5 pt-lg-5">
                Have consent forms ? Vaccination forms? No problem upload your forms and hirebase will allow your employees to digitally sign them.
              </h3>
            </div>
            <div className="col-lg-6 p-5">
              <div className="d-flex justify-content-center">
                <img src={documentSignatureImg} className="w-75" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container bg-light-gray" >
        <SectionDemo
          openModalSignUp={props.openModalSignUp}
        />
      </div>
    </>
  )
}

export default Onboarding;