import React, { useEffect, useState } from "react";
import './signUp.css';
import { useHistory } from "react-router";
import CustomButton from "../controls/buttons/customButton";

import laptopImg from './../../assets/img/page/laptop.png';
import lineRoundPathImg from './../../assets/img/clipart/line-round-path.png';
import circleRedImg from './../../assets/img/clipart/circle-red.png';
import squreAueaBlueImg from './../../assets/img/clipart/squre-auea-blue.png';
import Logo from "../logo/logo";
// import logoImg from '../../assets/img/logo/Hirebase-logo-dark-light.png';
import logoImg from '../../assets/img/logo/logo-signup.png';
import thankYouImg from '../../assets/img/page/rocket.gif';
import closeImg from '../../assets/img/clipart/close.png';

import { useDispatch, useSelector } from "react-redux";
import {
  addFullName,
  addEmail,
  addPhoneNumber,
  addCompanyName,
  addPosition,
  addNumberOfEmployees,
  addNumberOfApplicantPerMonth,
  addSubscribe,
  resetSignUp
} from "./reducerSlices/signUpSlice";

import ReactGA from 'react-ga4';


function SignUp(props) {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const fullName = useSelector((state) => state.signUp.fullName);
  const email = useSelector((state) => state.signUp.email);
  const phoneNumber = useSelector((state) => state.signUp.phoneNumber);
  const companyName = useSelector((state) => state.signUp.companyName);
  const position = useSelector((state) => state.signUp.position);
  const numberOfEmployees = useSelector((state) => state.signUp.numberOfEmployees);
  const numberOfApplicantPerMonth = useSelector((state) => state.signUp.numberOfApplicantPerMonth);
  const subscribe = useSelector((state) => state.signUp.subscribe);

  const [validfullName, setValidfullName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validNumberOfEmployees, setValidNumberOfEmployees] = useState(true);
  const [validNumberOfApplicantPerMonth, setValidNumberOfApplicantPerMonth] = useState(true);
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [validCompanyName, setValidCompanyName] = useState(true);
  const [validPosition, setValidPosition] = useState(true);

  const [emailSent, setEmailSent] = useState(false);
  const [thankYouFullNameMsg, setThankYouFullNameMsg] = useState("");

  useEffect(() => {
    email !== '' &&
      setValidEmail(validateEmail(email));
  });

  const onFullName = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z\s]/g, '');

    const isValid = /^[a-zA-Z]+(?: [a-zA-Z]*)?$/.test(value);

    if (isValid || value === "") {
      dispatch(addFullName(value));
    }

    if (value === "") {
      setValidfullName(false);
    } else {
      setValidfullName(true);
    }

  };

  const onEmail = (e) => {
    dispatch(addEmail(e.target.value))
    setValidEmail(validateEmail(e.target.value))
  }

  const onChangePhoneNumberbk = (e) => {
    let phoneNumber = parseInt(e.target.value.replace(/[^0-9.]/g, ""));
    dispatch(addPhoneNumber(formatPhoneNumber(phoneNumber ? phoneNumber : '')))
    if (e.target.value === '') {
      setValidPhoneNumber(false);
    } else {
      setValidPhoneNumber(true);
    }
  }

  const onChangePhoneNumber = (e) => {
    let phoneNumber = e.target.value.replace(/[^0-9]/g, "");

    if (phoneNumber.length > 15) {
      phoneNumber = phoneNumber.slice(0, 15);
    }

    dispatch(addPhoneNumber(formatPhoneNumber(phoneNumber ? phoneNumber : '')))

    if (phoneNumber === '' || phoneNumber.length < 8 || phoneNumber.length > 15) {
      setValidPhoneNumber(false);
    } else {
      setValidPhoneNumber(true);
    }
  };

  const onCompanyName = (e) => {
    dispatch(addCompanyName(e.target.value))
    if (e.target.value === '') {
      setValidCompanyName(false);
    } else {
      setValidCompanyName(true);
    }
  }

  const onPosition = (e) => {
    dispatch(addPosition(e.target.value))
    if (e.target.value === '') {
      setValidPosition(false);
    } else {
      setValidPosition(true);
    }
  }

  const onNumberOfEmployees = (e) => {
    dispatch(addNumberOfEmployees(e.target.value))
    if (e.target.value === '') {
      setValidNumberOfEmployees(false);
    } else {
      setValidNumberOfEmployees(true);
    }
  }

  const onNumberOfApplicantPerMonth = (e) => {
    dispatch(addNumberOfApplicantPerMonth(e.target.value))
    if (e.target.value === '') {
      setValidNumberOfApplicantPerMonth(false);
    } else {
      setValidNumberOfApplicantPerMonth(true);
    }
  }

  const onSubscribe = (e) => {
    dispatch(addSubscribe(!subscribe))
  }

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
  }

  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true
    }
    return false
  }

  const history = useHistory();

  async function onCreateAccount(e) {

    if (loading) return;

    fullName === '' && setValidfullName(false)
    email === '' && setValidEmail(false)
    phoneNumber === '' && setValidPhoneNumber(false)
    companyName === '' && setValidCompanyName(false)
    position === '' && setValidPosition(false)
    numberOfEmployees === '' && setValidNumberOfEmployees(false)
    numberOfApplicantPerMonth === '' && setValidNumberOfApplicantPerMonth(false)

    if (fullName && email && phoneNumber && companyName && position && numberOfEmployees && numberOfApplicantPerMonth && validEmail) {
      setLoading(true);

      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email,
          name: fullName,
          phoneNumber: phoneNumber,
          companyName: companyName,
          position: position,
          numberOfEmployees: numberOfEmployees,
          numberOfApplicantPerMonth: numberOfApplicantPerMonth,
          subscription: subscribe
        })
      };

      // fetch('https://hirebaseapi.azurewebsites.net/api/Identity/EnquiryEmail', options)
      //   .then(response => response.json())
      //   .then(response => console.log(response.statusCode == 200 ? setEmailSent(true) : setEmailSent(false)))
      //   .catch(err => console.error(err));

      try {
        const response = await fetch('https://hirebaseapi.azurewebsites.net/api/Identity/EnquiryEmail', options);
        const data = await response.json();
        setEmailSent(response.status === 200);
        if (response.status === 200) {
          setThankYouFullNameMsg(fullName);
          dispatch(resetSignUp());
        }
      } catch (err) {
        console.error(err);
        setEmailSent(false);
      } finally {
        setLoading(false);
      }
    }

    setLoading(false);
    //GA4 Tracking
    ReactGA.event({
      category: 'Create Account',
      action: 'Create Account',
      label: 'Create Account'
    });

  }

  const onSignIn = (e) => {
    // history.push('/');
  }


  return (
    <>
      <div className="row">
        {emailSent ?
          <>
            <div className="col-12 signup-card-full">
              <div className="signup-close" onClick={props.closeModal}>
                <i className="fa fa-times" aria-hidden="true" ></i>
                {/* <img src={closeImg} className="w-100" /> */}
              </div>
              <div className="d-flex justify-content-center pt-5">
                <img src={thankYouImg} className="w-50" />

              </div>
              <div className="d-flex justify-content-center">
                <div className="signup-heading p-5">
                  Thank You, {thankYouFullNameMsg}
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="signup-sub-heading pb-1">
                  Please verify your account. We have sent you an email.
                  {/* <span className="signup-link"> {fullName} </span> */}
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="signup-sub-heading pb-5">
                  Set up your company account.
                  {/* <span className="signup-link"> {fullName} </span> */}
                </div>
              </div>
              {/* <div className="pt-5">
                <CustomButton className="signup-button" text="Close" onClick={props.closeModal} />
              </div> */}
            </div>
          </>
          :
          <>
            <div className="col-md-5  signup-card-left signup-left-bg">
              <a onClick={props.closeModal} className="signup-logo">
                <Logo src={logoImg} />
              </a>
            </div>
            <div className="col-md-7 p-0 signup-right-bg">
              <div className="signup-card-right">

                <img src={lineRoundPathImg} style={{ width: "70px" }} className="position-absolute" />
                <div className="signup-container  p-5">
                  <div className="signup-close" onClick={props.closeModal}>
                    <i className="fa fa-times" aria-hidden="true" ></i>
                    {/* <img src={closeImg} className="w-100" /> */}
                  </div>
                  <div className="signup-heading">
                    Let's Chat  {emailSent}
                  </div>


                  <div className="row p-0 pt-5" >
                    <div className="form-group col-lg-6">
                      <label className="mb-0">Full Name</label>
                      <input type="text" className={`signup-input ${!validfullName && 'signup-input-invalid'}`} placeholder="Enter Full Name" value={fullName} onChange={e => onFullName(e)} />
                      {
                        !validfullName &&
                        <small className="text-danger">Enter Full Name</small>
                      }
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="mb-0">Email</label>
                      <input type="email" className={`signup-input ${!validEmail && 'signup-input-invalid'}`} placeholder="Enter Email" value={email} onChange={e => onEmail(e)} />
                      {
                        !validEmail &&
                        <small className="text-danger">Enter Valid Email Address</small>
                      }
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="mb-0">Phone Number</label>
                      <input type="tel" className={`signup-input ${!validPhoneNumber && 'signup-input-invalid'}`} placeholder="Enter Phone Number" value={phoneNumber} onChange={e => onChangePhoneNumber(e)} />
                      {
                        !validPhoneNumber &&
                        <small className="text-danger">Enter Phone Number</small>
                      }
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="mb-0">Company Name</label>
                      <input type="text" className={`signup-input ${!validCompanyName && 'signup-input-invalid'}`} placeholder="Enter Company Name" value={companyName} onChange={e => onCompanyName(e)} />
                      {
                        !validCompanyName &&
                        <small className="text-danger">Enter Company Name</small>
                      }
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="mb-0">Position</label>
                      <input type="text" className={`signup-input ${!validPosition && 'signup-input-invalid'}`} placeholder="Enter position" value={position} onChange={e => onPosition(e)} />
                      {
                        !validPosition &&
                        <small className="text-danger">Enter Position</small>
                      }
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="mb-0">Number of Employees</label>
                      <select className={`signup-input  ${!validNumberOfEmployees && 'signup-input-invalid'}`} value={numberOfEmployees !== "" ? numberOfEmployees : ''} placeholder="Select" onChange={e => onNumberOfEmployees(e)} >
                        <option disabled selected value=""></option>
                        <option value="10">Under-10</option>
                        <option value="11-50">11-50</option>
                        <option value="51-200">51-200</option>
                        <option value="201-1000">201-1000</option>
                        <option value="Over-10000">Over-1000</option>
                      </select>
                      {
                        !validNumberOfEmployees &&
                        <small className="text-danger">Select Number Of Employees</small>
                      }
                    </div>
                    <div className="form-group col-lg-6">
                      <label className="mb-0">Number of Applicants Per Month</label>
                      <select className={`signup-input ${!validNumberOfApplicantPerMonth && 'signup-input-invalid'}`} value={numberOfApplicantPerMonth ? numberOfApplicantPerMonth : ''} placeholder="Select" onChange={e => onNumberOfApplicantPerMonth(e)} >
                        <option disabled selected value=""></option>
                        <option value="10">Under-10</option>
                        <option value="11-50">11-50</option>
                        <option value="51-200">51-200</option>
                        <option value="201-1000">201-1000</option>
                        <option value="Over-10000">Over-1000</option>
                      </select>
                      {
                        !validNumberOfApplicantPerMonth &&
                        <small className="text-danger">Select Number Of Applicants</small>
                      }
                    </div>
                    <div className="col-md-12">
                      <div className="d-md-inline-block"><input type="checkbox" defaultChecked={subscribe} onChange={onSubscribe} /></div>
                      <div className="d-md-inline-block pl-2"><label>Subscribe to newsletter</label></div>
                    </div>
                    <div className="col-md-12">
                      {/* <div className="float-right"> */}
                      {/* <CustomButton className="signup-button" text="Get a free trial" onClick={onCreateAccount} disabled={fullName && email && phoneNumber && companyName && position && numberOfEmployees && numberOfApplicantPerMonth && validEmail ? false : true} /> */}
                      {/* </div> */}

                      <CustomButton
                        className="signup-button"
                        text="Get a free trial"
                        disabled={loading}
                        loading={loading}
                        onClick={onCreateAccount}
                      />

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </>
        }
      </div>
    </>
  );
}

export default SignUp;