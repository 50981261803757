export const sections = {
    COMPANY_DETAILS: 0
};

export const onValueChanged = (company, e, section, value) => {
    let _company = JSON.parse(JSON.stringify(company));
    if (section == sections.COMPANY_DETAILS) {
        return _saveCompanyDetails(_company, e, value);
    }
};

const _saveCompanyDetails = (company, e, value) => {
    if (e.target.name === "txtCompanyName") {
        company.CompanyName.Data = e.target.value;
    }
    else if (e.target.name === "txtCompanyPhoneNumber") {
        company.CompanyPhoneNumber.Data = e.target.value;
    }
    else if (e.target.name === "txtCompanyAddress") {
        company.CompanyAddress.Data = e.target.value;
    }
    else if (e.target.name === "txtCompanyWebsite") {
        company.CompanyWebsite.Data = e.target.value.toLowerCase();
    }
    else if (e.target.name === "txtWorkspaceURL") {
        company.WorkspaceURL.Data =  e.target.value.toLowerCase();
    }
    else if (e.target.name === "drpStatus") {
        company.Status.Data = e.target.value;
    }
    else if (e.target.name === "drpPlan") {
        company.Plan.Data = e.target.value;
    }
    else if (e.target.name === "txtEmail") {
        company.Email.Data = e.target.value;
    }
    else if (e.target.name === "txtPassword") {
        company.Password.Data = e.target.value;
    }
    else if (e.target.name === "txtLocationName") {
        company.LocationName.Data = e.target.value;
    }
    else if (e.target.name === "txtFirstName") {
        company.FirstName.Data = e.target.value;
    }
    else if (e.target.name === "txtLastName") {
        company.LastName.Data = e.target.value;
    }
    
    return company;
};