import React from "react";
import './css/section1.css';
import flowImg from '../../assets/img/page/with-border-main-anim.png';
import curveLineLeftArrowRoundVerticalPinkImg from '../../assets/img/clipart/curve-line-left-arrow-round-vertical-pink.svg';
import { AnimationOnScroll } from "react-animation-on-scroll";
import Typewriter from 'typewriter-effect';
import SectionFreeDemo from "./sectionFreeDemo";
import EmailSubscribe from "../emailSubscribe/emailSubscribe";

function Section1(props) {
  return (
    <div className="row pt-7 custom-row" >
      <div className="col-lg-6 mt-lg-5 p-md-5">
        <AnimationOnScroll animateIn="animate__fadeInLeft">
          {/* <div className="custom-animation-1" style={{ marginBottom: "5%", marginLeft: "20%" }}></div> */}
          <h1 className="section1-title mb-2">Blazingly <span className="section1-title-bold">fast</span> process for <span className="section1-title-bold">
            <Typewriter
              options={{
                strings: ['OnBoarding', 'Hiring', 'Tracking', 'Dispatching'],
                autoStart: true,
                loop: true,
                cursorClassName: 'Typewriter__cursor'
              }}
            />
          </span></h1>
          {/* <div className="section1-title-2 text-pink">
            <div className="animate__animated animate__slideInLeft" >Hiring</div>
          </div> */}
          <img src={curveLineLeftArrowRoundVerticalPinkImg} alt="nophoto" class="section1-arrow"/>
          <p className="section1-message mt-5 pr-5">Hirebase is a modern applicant platform that helps teams connect, collaborate and deliver amazing experiences to employees</p>
          {/* <div className="custom-animation-2" style={{ marginLeft: "30%", marginTop: "5%" }}></div> */}
        </AnimationOnScroll>
        <EmailSubscribe onClick={props.openModalSignUp} emailSubscribeClass="pt-5"/>
       
      </div>
      <div className="col-lg-6 mt-4 px-lg-5 pb-5">
        <AnimationOnScroll animateIn="animate__fadeInRight" offset="1">
          {/* <img className="spin" src={starPinkImg} alt="star" style={{ marginTop: "-5%", marginLeft: "22%" }} /> */}
          <img className="shape shape-one px-5" src={flowImg} alt="Home" height="434px"></img>
        </AnimationOnScroll>
      </div>
    </div>
  )
}

export default Section1;