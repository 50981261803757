import React, { useState, useEffect } from "react";
import './css/contactUs.css';
import CustomButton from "../controls/buttons/customButton";
import SectionDemo from "../section/sectionDemo";

import curveLineAarrowRoundVerticalImg from '../../assets/img/clipart/curve-line-arrow-round-vertical.svg';
import compoundPathImg from '../../assets/img/clipart/compound-path.svg';
import diamondImg from '../../assets/img/clipart/diamond.png';


// import contactUsImg from "../../assets/img/page/contact-us.svg";
import contactUsImg from "../../assets/img/page/contact-us.png";
import thankYouImg from '../../assets/img/page/thank-you.png';

import ReactGA from 'react-ga4';

function ContactUs(props) {

  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [contactUsDetails, setContactUsDetails] = useState("");

  const [validfullName, setValidfullName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validPhoneNumber, setValidPhoneNumber] = useState(true);
  const [validCompanyName, setValidCompanyName] = useState(true);
  const [validContactUsDetails, setValidContactUsDetails] = useState(true);

  const [emailSent, setEmailSent] = useState(false);

  const onFullName = (e) => {
    setFullName(e.target.value);
    if (e.target.value === "") {
      setValidfullName(false);
    } else {
      setValidfullName(true);
    }
  }

  const onEmail = (e) => {
    setEmail(e.target.value);
    setValidEmail(validateEmail(e.target.value))
  }

  const onChangePhoneNumber = (e) => {
    let phoneNumber = parseInt(e.target.value.replace(/[^0-9.]/g, ""));
    setPhoneNumber(formatPhoneNumber(phoneNumber ? phoneNumber : ''));
    if (e.target.value === '') {
      setValidPhoneNumber(false);
    } else {
      setValidPhoneNumber(true);
    }
  }

  const onCompanyName = (e) => {
    setCompanyName(e.target.value);
    if (e.target.value === '') {
      setValidCompanyName(false);
    } else {
      setValidCompanyName(true);
    }
  }

  const onContactUsDetails = (e) => {
    setContactUsDetails(e.target.value);
    if (e.target.value === '') {
      setValidContactUsDetails(false);
    } else {
      setValidContactUsDetails(true);
    }
  }

  const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
  }

  const validateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true
    }
    return false
  }

  async function onContactUs(e) {

    fullName === '' && setValidfullName(false)
    email === '' && setValidEmail(false)
    phoneNumber === '' && setValidPhoneNumber(false)
    companyName === '' && setValidCompanyName(false)
    contactUsDetails === '' && setValidContactUsDetails(false)

    if (fullName && email && phoneNumber && companyName && contactUsDetails && validEmail) {
      const options = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email,
          FullName: fullName,
          PhoneNumber: phoneNumber,
          CompanyName: companyName,
          Details: contactUsDetails
        })
      };

      fetch('https://landerapi.azurewebsites.net/api/Identity/ContactUsEmail', options)
        .then(response => response.json())
        .then(response => console.log(response.statusCode == 200 ? setEmailSent(true) : setEmailSent(false)))
        // .then(response => console.log(response))
        .catch(err => console.error(err));
    }

    //GA4 Tracking
    ReactGA.event({
      category: 'Send Message',
      action: 'On Click Send Message',
      label: 'Contact Us'
    });
  }

  return (
    <>
      <div className="row page-container">
        <div className="container">
          <div className="row custom-row pb-5 bg-light-gray" >
            <div className="col-lg-12 mt-5 pt-5 p-5">
              <div className="d-flex justify-content-center">
                <h2 className="mb-0 contact-us-heading">Let’s start a discussion…</h2>
              </div>
            </div>

            {/* <div className="col-lg-12 px-md-5">
              <div className="d-flex justify-content-center">
              <img src={contactUsImg} alt="not found"></img>
              </div>
            </div> */}
          </div>
          {/* <img className="d-none d-lg-block" src={curveLineAarrowRoundVerticalImg} alt="no" style={{ position: "absolute", marginTop: "-4%", marginLeft: "20%", height: "15%" }} /> */}
          {/* <img className="d-none d-lg-block" src={compoundPathImg} alt="no" style={{ position: "absolute", marginTop: "-40px", marginLeft: "50%", height: "50px" }} /> */}

          <div className="d-flex">
            <div className="col-4 text-end">
              <img className="d-none d-lg-block contact-us-curveLineAarrowRoundVerticalImg" src={curveLineAarrowRoundVerticalImg} alt="no" />
            </div>
            <div className="col-4 text-center">
              <img className="" src={contactUsImg} alt="no" style={{ marginTop: "-75px", width: "150px" }} />
            </div>
            <div className="col-4 text-center">
              <img className="d-none d-lg-block" src={compoundPathImg} alt="no" style={{ marginTop: "20px", marginLeft: "0px", width: "50px" }} />
            </div>
          </div>

          <div className="row custom-row pb-5" >
            <div className="col-lg-12 px-md-5 pb-md-5">
              <div className="d-flex justify-content-center">
                <h1 className="contact-us-heading">Drop us a message and we'll reply quickly</h1>
              </div>
            </div>

            {emailSent ?
              <>
                <div className="col-12 signup-card-full">
                  <div className="d-flex justify-content-center pt-5">
                    <img src={thankYouImg} className="w-50" />
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="signup-heading p-5">
                      Thank You {fullName}
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <div className="signup-sub-heading pb-5">
                      for submitting request, HireBase team will be in touch with you soon.
                    </div>
                  </div>
                </div>
              </>
              :
              <>
                <div className="row custom-row px-md-5 mx-lg-5">
                  <div className="form-group col-md-6 px-lg-5 py-2">
                    <label className="mb-0">Full Name</label>
                    <input type="text" className={`signup-input ${!validfullName && 'signup-input-invalid'}`} placeholder="Enter Full Name" value={fullName} onChange={e => onFullName(e)} />
                    {
                      !validfullName &&
                      <small className="text-danger">Enter Full Name</small>
                    }
                  </div>
                  <div className="form-group col-md-6 px-lg-5 py-2">
                    <label className="mb-0">Email</label>
                    <input type="email" className={`signup-input ${!validEmail && 'signup-input-invalid'}`} placeholder="Enter Email" value={email} onChange={e => onEmail(e)} />
                    {
                      !validEmail &&
                      <small className="text-danger">Enter Valid Email Address</small>
                    }
                  </div>
                  <div className="form-group col-md-6 px-lg-5 py-2">
                    <label className="mb-0">Phone Number</label>
                    <input type="tel" className={`signup-input ${!validPhoneNumber && 'signup-input-invalid'}`} placeholder="Enter Phone Number" value={phoneNumber} onChange={e => onChangePhoneNumber(e)} />
                    {
                      !validPhoneNumber &&
                      <small className="text-danger">Enter Phone Number</small>
                    }
                  </div>
                  <div className="form-group col-md-6 px-lg-5 py-2">
                    <label className="mb-0">Company Name</label>
                    <input type="text" className={`signup-input ${!validCompanyName && 'signup-input-invalid'}`} placeholder="Enter Company Name" value={companyName} onChange={e => onCompanyName(e)} />
                    {
                      !validCompanyName &&
                      <small className="text-danger">Enter Company Name</small>
                    }
                  </div>
                  <div className="form-group col-md-12 px-lg-5 py-2">
                    <label className="mb-0">Contact Us Details</label>
                    <textarea className={`signup-input ${!validContactUsDetails && 'signup-input-invalid'}`} placeholder="Enter Contact Us Details" onChange={e => onContactUsDetails(e)} style={{ height: "7rem" }}>
                      {contactUsDetails}
                    </textarea>
                    {
                      !validContactUsDetails &&
                      <small className="text-danger">Enter Contact Us Details</small>
                    }
                  </div>
                  <div className="col-12 px-lg-5 py-2">
                    <div className="d-flex justify-content-center">
                      <CustomButton className="signup-button" text="Send Message" onClick={onContactUs} />
                    </div>
                  </div>
                </div>
              </>
            }

          </div>
        </div>
      </div>
      <div className="container" >
        <SectionDemo
          openModalSignUp={props.openModalSignUp}
        />
      </div>
    </>
  )
}

export default ContactUs;