import React, { useEffect } from "react";
import './collaborate.css';
import SectionDemo from "../section/sectionDemo";

import metricsImg from './../../assets/img/page/metrics.svg';
import curveLineLeftAarrowRoundVerticalImg from './../../assets/img/clipart/curve-line-left-arrow-round-vertical.svg';
import hirebasePeopleImg from './../../assets/img/page/hirebase-people.png';
import rethinkYourProcessImg from './../../assets/img/page/rethink-your-process.svg';
import CardTop from "../widgets/cardTop";

import topDispatchersImg from './../../assets/img/page/top-dispatchers.svg';
import yourStaffChartsImg from './../../assets/img/page/your-staff-chart.svg';
import numberOfActiveJobsImg from './../../assets/img/page/number-of-active-jobs.svg';
import noShowsImg from './../../assets/img/page/no-shows.svg';

import vectorLinePinkImg from './../../assets/img/page/vector-line-pink.svg';
import vectorLinePinkTwoImg from './../../assets/img/page/vector-line-pink-two.svg';

import registrationImg from './../../assets/img/widgets/registration.svg';
import clientImg from './../../assets/img/widgets/client.svg';
import interviewImg from './../../assets/img/widgets/interview.svg';
import dispatchedImg from './../../assets/img/widgets/dispatched.svg';
import callinImg from './../../assets/img/widgets/call-in.svg';
import calloutImg from './../../assets/img/widgets/call-out.svg';

import ReactGA from 'react-ga4';
import NumberBox from "../widgets/numberBox";

function Collaborate(props) {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <div className="row page-container">
        <div className="container" >
          <div className="row custom-row bg-light-gray">
            <div className="col-md-12  mt-5 p-md-5 pt-5">
              <div className="d-flex justify-content-center">
                <h1 className="collaborate-title">All-In-One <span className="collaborate-title-bold">Staffing</span> and <br /><span className="collaborate-title-underline">Dispatching</span> Solution</h1>
              </div>
              {/* <div className="d-flex justify-content-center">
                <h1 className="section1-title">Its time to Modernize </h1>
              </div> */}
              <img className="d-none d-lg-block curve-line-arrow-round" src={curveLineLeftAarrowRoundVerticalImg} alt="no" />
              <div className="d-flex justify-content-center pt-3">Hirebase was made to be a one stop shop for your staffing firm. </div>
              <div className="d-flex justify-content-center pt-5"><button type="button" className="btn button-b5 button-5" onClick={props.openModalSignUp}>Get Free Demo</button></div>
            </div>
          </div>
          <div className="row custom-row  bg-light-gray collaborate-hirebase-people-bg">
            <div className="col-md-12 p-md-5 ">
              <div className="d-flex justify-content-center">
                <img src={hirebasePeopleImg} className="w-100" />
              </div>
              <div className="d-flex justify-content-center pb-5">
                <img src={vectorLinePinkTwoImg} />
              </div>
            </div>
          </div>
          {/* <div className="row custom-row   p-md-5 ">
            <div className="col-md-12">
              <h1 className="d-flex justify-content-center section1-title text-center">Integrate And Streamline Your Process</h1>
            </div>
          </div> */}
          <div className="row custom-row  ">
            <div className="col-12 p-md-5">
              <div className="d-flex justify-content-center">
                <img src={rethinkYourProcessImg} className="" />
              </div>
            </div>
          </div>

          {/* <div className="row custom-row   p-4 p-md-5 ">
            <div className="col pt-5">
              <CardTop
                title={"Hiring"}
                description={"Embed Apply Now buttons on your job Board and website."}
                color="#47A2F2"
              />
            </div>
            <div className="col pt-5">
              <CardTop
                title={"Onboarding"}
                description={"Move your entire process to your website and Ipad App."}
                color="#FF7555"
              />
            </div>
            <div className="col pt-5">
              <CardTop
                title={"Generate Docs"}
                description={"Generate I9 and W4 forms."}
                color="#13AC95"
              />
            </div>
            <div className="col pt-5">
              <CardTop
                title={"Fill Job Orders"}
                description={"Assign employees, and dispatch employees to jobs."}
                color="#9661D2"
              />
            </div>
            <div className="col pt-5">
              <CardTop
                title={"Payroll Reports"}
                description={"Payroll reports generated with a single button click . Never miss payroll again."}
                color="#D8A641"
              />
            </div>
          </div> */}

          {/* <div className="row custom-row bg-light-gray  " >
            <div className="col-lg-6 p-md-5">
              <img src={metricsImg} />
            </div>
            <div className="col-lg-6 p-md-5 align-self-center">
              <h1 className="section1-title">Dashboards and Metrics</h1>
              <h3 className="section1-message pt-4">
                Ever wonder about how productive your agency is? Hirebase dashboards show critical metrics you need in order to run your business.
              </h3>
            </div>
          </div> */}

          <div className="row custom-row   p-md-5 ">
            <div className="col-md-12">
              <div className="d-flex justify-content-center">
                <img src={vectorLinePinkImg} />
              </div>
              <h1 className="d-flex justify-content-center section1-title text-center">Dashboards and Metrics</h1>
              <h3 className="d-flex justify-content-center section1-message pt-4">
                Ever wonder about how productive your agency is? Hirebase dashboards
              </h3>
              <h3 className="d-flex justify-content-center section1-message">
                show critical metrics you need in order to run your business.
              </h3>
            </div>
          </div>

          <div className="row custom-row py-5 mb-5" style={{ background: "#0A253E" }}>

            <div className="row p-md-5 w-100 pl-4">
              <div className="col-lg-2 col-sm-6 col-12 pb-4 pb-md-0">
                <NumberBox text="Registrations" count="378" percentage="16" src={registrationImg} />
              </div>
              <div className="col-lg-2 col-sm-6 col-12 pb-4 pb-md-0">
                <NumberBox text="# of Clients" count="-20" percentage="10" src={clientImg} />
              </div>
              <div className="col-lg-2 col-sm-6 col-12 pb-4 pb-md-0">
                <NumberBox text="Interviews Today" count="102" percentage="38" src={interviewImg} />
              </div>
              <div className="col-lg-2 col-sm-6 col-12 pb-4 pb-md-0">
                <NumberBox text="Dispatched" count="160" percentage="23" src={dispatchedImg} />
              </div>
              <div className="col-lg-2 col-sm-6 col-12 pb-4 pb-md-0">
                <NumberBox text="Called In" count="-25" percentage="11" src={callinImg} />
              </div>
              <div className="col-lg-2 col-sm-6 col-12 pb-4 pb-md-0">
                <NumberBox text="Called Out" count="230" percentage="28" src={calloutImg} />
              </div>
            </div>

            <div className="col-lg-5 p-md-6">
              <img src={topDispatchersImg} className="w-100 pb-4 pb-md-0" />
            </div>
            <div className="col-lg-4 p-md-6">
              <img src={yourStaffChartsImg} className="w-100 pb-4 pb-md-0" />
            </div>
            <div className="col-lg-3 p-md-6">
              <div className="d-flex justify-content-center">
                <img src={numberOfActiveJobsImg} className="pb-4 pb-md-0" />
              </div>
              <img src={noShowsImg} className="w-100 pt-md-5 pb-4 pb-md-0" />
            </div>
          </div>

        </div>
      </div>
      <div className="container bg-light-gray" >
        <SectionDemo
          openModalSignUp={props.openModalSignUp}
        />
      </div>
    </>
  )
}

export default Collaborate;