import React from "react";
import './css/sectionFreeDemo.css';
import EmailSubscribe from "../emailSubscribe/emailSubscribe";
import curveLineClipart from '../../assets/img/clipart/curve-line.png';

function SectionFreeDemo(props) {
  return (
    <div className="row sectionFreeDemo-bg mt-5 custom-row">
      {/* <div className="col-lg-6 p-md-5"> */}
      <div className="col-lg-6 py-md-5 pl-md-5 pr-md-0">
        <div className="animate__animated animate__fadeInUp animate__delay-1s">
          <h2 className="sectionFreeDemo-title">
            {props.title ?
              props.title
              : ''
            }

          </h2>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="row">
          <div className="ml-auto">
            <div className="mr-0">
              <img src={curveLineClipart} className="pl-5" alt="no" />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center pt-2 pr-md-5 pl-md-5 pb-3 pb-md-5 pb-lg-0">
          <EmailSubscribe onClick={props.openModalSignUp} />
        </div>
      </div>
      <div className="circle-left"></div>
    </div>
  )
}

export default SectionFreeDemo;