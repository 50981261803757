import React from "react";
// import hirebaseLogo from '../../assets/img/logo/Hirebase-logo.png';
import hirebaseLogo from '../../assets/img/logo/hirebase-logo.svg';

function Logo(props) {
  return (
    <img className="" src={props.src ? props.src : hirebaseLogo } alt="logo" style={{width:"200px"}}></img>
  )
}

export default Logo;