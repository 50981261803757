import React, { useEffect } from "react";
import './homePage.css';

// Section Import
import Section1 from "../section/section1";
import SectionFreeDemo from "../section/sectionFreeDemo";
import Section2 from "../section/section2";
import SectionCount from "../section/sectionCount";
import SectionTestimonials from "../section/sectionTestimonials";
import SectionDemo from "../section/sectionDemo";
//import SectionCollaborate from "../section/sectionCollaborate.js";
import Section3 from "../section/section3";


import diamondImg from '../../assets/img/clipart/diamond.png';
import circleImg from '../../assets/img/clipart/circle.png';
import SectionOnePlace from "../section/sectionOnePlace";

import ReactGA from 'react-ga4';
import SectionTestimonialsNew from "../section/sectionTestimonialsNew";
import SectionEcosystem from "../section/sectionEcosystem";

function HomePage(props) {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <div className="row page-container">
        <div className="container" >
          <Section1 openModalSignUp={props.openModalSignUp}/>
          {/* <SectionFreeDemo
            title={<>Interested in Learning more ? <br /><span className="sectionFreeDemo-title-bold">Your Free Trial Awaits</span></>}
            openModalSignUp={props.openModalSignUp}
          /> */}
          <Section2 />
          {/* <img className="shape shape-three" src={diamondImg} alt="no" style={{ marginTop: "-5%", marginLeft: "22%" }} /> */}
          <SectionOnePlace />
          {/* <img className="shape shape-three" src={circleImg} alt="no" style={{ position: "absolute", marginTop: "0.5%", marginLeft: "15%" }} /> */}
          <Section3 />
          {/* <SectionCount /> */}
          {/* <SectionTestimonials /> */}
          <SectionEcosystem openModalSignUp={props.openModalSignUp}/>
          <SectionTestimonialsNew />
        </div>
      </div>
      <div className="row page-container">
        <div className="container bg-light-gray" >
          <SectionDemo
            openModalSignUp={props.openModalSignUp}
          />
        </div>
      </div>
    </>
  )
}

export default HomePage;