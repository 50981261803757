// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

import axios from 'axios';

export const HirebaseAPI = axios.create({
    baseURL: `https://hirebaseapi.azurewebsites.net/api/` //Hirebase Demo Enviroment 
    // baseURL: `https://localhost:44377/api/` //local Enviroment 
    // baseURL: `/fakeApi/`
    //baseURL: `https://localhost:5001/api/`
});

export const fetchStatus = {
    IDLE: 'idle',
    LOADING: 'loading',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
    DONE: 'done'
}

export const masterTenantId = "HireBase";

const subDomainName = window.location.host.split('.')[0];
// export const tenantId = subDomainName.charAt(0).toUpperCase() + subDomainName.slice(1);  //  Live Enviroment
// 
export const tenantId = "HireBase"; 