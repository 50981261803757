import { configureStore } from '@reduxjs/toolkit';

import signUprSlice  from './component/signUp/reducerSlices/signUpSlice';
import subscriptionSlice from './component/pages/subscription/reducerSlices/subscriptionSlice';

export const store = configureStore({
  reducer: {
    signUp: signUprSlice,
    subscription: subscriptionSlice
  },
})