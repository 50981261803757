import React from "react";
import './topNavBar.css';
import Logo from "../logo/logo";
import { useHistory } from "react-router";
import CustomButton from "../controls/buttons/customButton";

import ReactGA from 'react-ga4';

function TopNavBar(props) {
  const history = useHistory();

  const onHome = (e) => {
    history.push('/');
  }

  const onStaffing = (e) => {
    //GA4 Tracking Open page collaborate
    ReactGA.event({
      category: 'Staffing Companies',
      action: 'Staffing Companies',
      label: 'Staffing Companies'
    });

    history.push('/StaffingCompany');
  }

  const onClickGetPriceing = () => {
    //GA4 Tracking Get Priceing
    ReactGA.event({
      category: 'Get Pricing',
      action: 'Get Pricing',
      label: 'Get Pricing'
    });
    
    history.push('/pricing');
    // props.openModalSignUp()
  }

  return (
    <>
      <div className="header-container fixed-top">
        <div className="row page-container">
          <div className="container" >
            <div className="row pt-0 custom-row" >
              <nav className="w-100 navbar navbar-expand-lg bg-light navbar-light pt-0 pb-0 pl-md-5 pr-md-5">
                <a className="navbar-brand hover-pointer" onClick={onHome}><Logo /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                  <div className="">
                    <ul className="navbar-nav top-nav-bar-menu mr-auto">
                      {/* <li className="nav-item">
                        <div className="btn-group show">
                          <button type="button" className="btn dropdown-toggle status-btn-text" data-toggle="dropdown"
                            aria-haspopup="false">
                            Use Cases
                          </button>
                          <div className="dropdown-menu  status-pane">
                            <a className="dropdown-item hover-pointer" onClick={onStaffing}>Staffing Companies</a>
                          </div>
                        </div>
                      </li> */}
                      <li className="nav-item">
                        <a className="nav-link hover-pointer" onClick={onClickGetPriceing}>Get Pricing</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link hover-pointer" onClick={onStaffing}>Staffing Companies</a>
                      </li>
                    </ul>
                  </div>
                  <div className="ml-auto">
                    <ul className="navbar-nav top-nav-bar-menu mr-auto">
                      {/* <li className="nav-item mr-0">
                      <CustomButton text="Sign In"/>
                    </li> */}
                      <li className="nav-item ml-3 mr-0">
                        {/* <CustomButton text="Get Started" onClick={onSignUp} /> */}
                        <CustomButton text="Get Started" onClick={props.openModalSignUp} />
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopNavBar;