import React, { useState } from 'react';
import './pricing.css';
import SectionDemo from '../section/sectionDemo';
import YesNoRadio from '../controls/checkers/yesNoRadio';
import logoCFSImg from '../../assets/img/logo/logo-cfs.png';
import logoGmailImg from '../../assets/img/logo/logo-gmail.png';
import logoOutlookImg from '../../assets/img/logo/logo-outlook.png';
import logoChatgptImg from '../../assets/img/logo/logo-chatgpt.png';
import logoTwilioImg from '../../assets/img/logo/logo-twilio.png';
import logoPaychexImg from '../../assets/img/logo/logo-paychex.png';
import logoAdpImg from '../../assets/img/logo/logo-adp.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import ReactGA from 'react-ga4';

function Pricing(props) {

  const [isYearly, setIsYearly] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const onTabSelected = (e, v) => {
    e.preventDefault();
    setSelectedTab(v);
  };


  const featureArr = [
    {
      feature: "ATS",
      free: "y",
      basic: "y",
      standard: "y",
      enterprise: "y",
      customizations: "",
      product: ""
    },
    {
      feature: "Embed online application on your website(english / spanish)",
      free: "y",
      basic: "y",
      standard: "y",
      enterprise: "y",
      customizations: "",
      product: ""
    },
    {
      feature: "Onboarding via app ( Communcations platform , Document management)",
      free: "",
      basic: "y",
      standard: "y",
      enterprise: "y",
      customizations: "",
      product: ""
    },
    {
      feature: "Applicant tracking Platform",
      free: "",
      basic: "y",
      standard: "y",
      enterprise: "y",
      customizations: "",
      product: "Back Office"
    },
    {
      feature: "Concent form generation e-signatures (I9 / W4 /EDD)",
      free: "",
      basic: "",
      standard: "y",
      enterprise: "y",
      customizations: "Custom Forms Per request may require development.",
      product: "APP or Wizard?"
    },
    {
      feature: "Training videos*",
      free: "",
      basic: "",
      standard: "",
      enterprise: "y",
      customizations: "",
      product: ""
    },
    {
      feature: "Send SMS to workforce",
      free: "",
      basic: "",
      standard: "y",
      enterprise: "y",
      customizations: "$.05 cent per message send and receive",
      product: ""
    },
    {
      feature: "Document generation",
      free: "",
      basic: "",
      standard: "y",
      enterprise: "y",
      customizations: "First 1000 docs free then $.10",
      product: ""
    },
    {
      feature: "Job order fulfilment and dispatching Unlimited jobs",
      free: "",
      basic: "",
      standard: "",
      enterprise: "y",
      customizations: "",
      product: ""
    },
    {
      feature: "Payroll reports",
      free: "",
      basic: "",
      standard: "",
      enterprise: "y",
      customizations: "",
      product: "Back Office"
    },
    {
      feature: "Multi-branch / location management",
      free: "",
      basic: "",
      standard: "y",
      enterprise: "y",
      customizations: "",
      product: ""
    },
    {
      feature: "Management Email Reports",
      free: "",
      basic: "",
      standard: "",
      enterprise: "y",
      customizations: "",
      product: ""
    },
    {
      feature: "Admin Dashboards",
      free: "",
      basic: "",
      standard: "",
      enterprise: "y",
      customizations: "",
      product: ""
    },
    {
      feature: "Geo fencing workforce arrives at location",
      free: "",
      basic: "",
      standard: "",
      enterprise: "y",
      customizations: " Usage cost $1 per user / job / day",
      product: ""
    },
    {
      feature: "Candidate custom tag (available, blacklisted,...)",
      free: "",
      basic: "",
      standard: "y",
      enterprise: "y",
      customizations: "",
      product: ""
    },
    {
      feature: "Resume parsing",
      free: "",
      basic: "",
      standard: "",
      enterprise: "",
      customizations: "",
      product: ""
    },
    {
      feature: "Automatic Reccomendation engine on candidates",
      free: "",
      basic: "",
      standard: "",
      enterprise: "y",
      customizations: "",
      product: ""
    },
    {
      feature: "Ability to add custom questions *",
      free: "",
      basic: "",
      standard: "",
      enterprise: "y",
      customizations: "",
      product: ""
    },
    {
      feature: "Custom access levels",
      free: "",
      basic: "",
      standard: "",
      enterprise: "",
      customizations: "",
      product: ""
    },
    {
      feature: "Application Tiraging and assignment.",
      free: "",
      basic: "",
      standard: "",
      enterprise: "",
      customizations: "",
      product: ""
    },
  ]

  const planPricesMonthly = {
    ATS: {
      Basic: 25,
      BasicUser: 0.6,
      Standard: 99,
      StandardUser: 1.8,
      Enterprise: 249,
      EnterpriseUser: 3.6,
    },
    CRM: {
      SalesPipeline: 10,
      MobileSalesApp: 25,
    }
  }

  const planPricesYearly = {
    ATS: {
      Basic: 21,
      BasicUser: 0.5,
      Standard: 83,
      StandardUser: 1.5,
      Enterprise: 208,
      EnterpriseUser: 3
    },
    CRM: {
      SalesPipeline: 10,
      MobileSalesApp: 25,
    }
  };

  const highlights = {
    ATS: {
      Free: [
        "Online form employee onboarding",
        "Applicant Tracking",
        "Export to Excel"
      ],
      Basic: [
        "In office Kiosk",
        "Ability to customize questions",
        "Official Document Generation",
        "Ability to send SMS and Email",
        "Automatic Call Tracking",
        "Custom access levels"
      ],
      Standard: [
        "SMS Messaging",
        "Automated Responses",
        "Auto Generate Official Forms",
        "E-Signatures",
        "Job Order Management",
        "Dispatching",
        "Team Performance Dashboards",
        "API Access"
      ],
      Enterprise: [
        "Data isolation",
        "Custom App development",
        "Custom in office Kiosk",
        "No limit data uploads"
      ]
    },
    CRM: {
      SalesPipeline: [
        "Automatically create sales prospects based on employees previous work history",
        "Call Tracking",
        "Document upload",
        "Lead Workflow",
        "Meeting scheduling",
        "Meeting followup",
        "Contact management",
        "Sales Calendar",
        "Dashboards"
      ],
      MobileSalesApp: [
        "Business Card Scanning",
        "Meeting contact management",
        "Employee tracking with maps",
        "Smart sales routing.",
        "Lead Queues",
        "Lead Maps based on location"
      ]
    }
  };

  const onClickPricing = (msg) => {
    //GA4 Tracking
    ReactGA.event({
      category: msg,
      action: msg,
      label: msg
    });

    props.openModalSignUp()
  }

  return (
    <>
      <div className="row page-container">

        <div className="container" >

          <div className="row custom-row">
            <div className="col-md-12  mt-5 pt-5 p-md-5 ">
              <h1 className="d-flex justify-content-center section1-title">
                Pricing
              </h1>
              {/* <div className="d-flex justify-content-end  pb-3">
                <YesNoRadio
                  name="rdbtnLang"
                  yesText={`Yearly`}
                  noText={`Monthly`}
                  onSelectionChanged={(e, v) => setIsYearly(!isYearly)}
                  value={isYearly ? "Y" : "N"} />
              </div> */}
            </div>
          </div>


          <div className="row custom-row">
            <div className="col-md-12 px-md-5">
              <div className="pricing-section">
                <div className="pricing-section-header">
                  <ul className="nav">
                    <li className="nav-item">
                      <span className={`nav-link nav-section ${selectedTab === 0 ? "nav-section-active" : ""}`} onClick={(e) => onTabSelected(e, 0)}><i class="fa fa-file mr-2"></i> ATS</span>
                    </li>
                    <li className="nav-item">
                      <span className={`nav-link nav-section ${selectedTab === 1 ? "nav-section-active" : ""}`} onClick={(e) => onTabSelected(e, 1)}><i class="fa fa-users mr-2"></i> CRM</span>
                    </li>
                    <li className="nav-item">
                      <span className={`nav-link nav-section ${selectedTab === 2 ? "nav-section-active" : ""}`} onClick={(e) => onTabSelected(e, 2)}><i class="fa fa-bahai mr-2"></i> Platform</span>
                    </li>
                  </ul>
                </div>
                <div className="pricing-section-body">
                  {selectedTab === 0 && <>
                    <span className="d-flex justify-content-center m-3 mb-4 pricing-section-subtitle">
                      The ATS Hub includes
                    </span>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="plans-list">
                          <AnimationOnScroll animateIn="animate__slideInRight" animateOnce={true}>
                            <div className="plan">
                              <div className="plan-header">
                                {/* <p className="plan-label">ATS <i class="fa fa-cog fa-spin"></i></p> */}
                                <h2 className="plan-name">Free </h2>
                                <div class="plan-prices">
                                  <div class="plan-price-wrapper">
                                    <p class="plan-price-wrapper">
                                      <span class="plan-price">
                                        <sup class="plan-price-currency">$</sup>
                                        <span class="plan-price-num">0</span>
                                      </span>
                                    </p>
                                    {/* <p class="plan-users-num">For up to 5 employees</p>
                                  <p class="plan-additional-users">&nbsp;</p> */}
                                  </div>
                                </div>
                              </div>
                              <a class="plan-button-bordered-pink" href="#" onClick={() => onClickPricing("Pricing ATS Free")}>Start for free</a>
                              <div class="highlights">
                                <h3>Plan Highlights</h3>
                                <ul>
                                  {highlights.ATS.Free.map((highlight, index) => (
                                    <li key={index}>{highlight}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </AnimationOnScroll>

                          <div className="plan">
                            <AnimationOnScroll className="plan-animation" animateIn="animate__slideInRight" animateOnce={true}>
                              <div className="plan-header">
                                {/* <p className="plan-label">ATS <i class="fa fa-cog fa-spin"></i></p> */}
                                <h2 className="plan-name">Basic </h2>
                                <div class="plan-prices">
                                  <div class="plan-price-wrapper">
                                    <p class="plan-price-wrapper">
                                      <span class="plan-price">
                                        <sup class="plan-price-currency">$</sup>
                                        <span class="plan-price-num">{isYearly ? planPricesYearly["ATS"]["Basic"] : planPricesMonthly["ATS"]["Basic"]}</span>
                                      </span>
                                      <span class="plan-price-duration">/mo {isYearly && 'x 12'}</span>
                                    </p>
                                    {/* <p class="plan-users-num">For first 30 users</p>
                                  <p class="plan-additional-users"><b>${isYearly ? planPricesYearly["ATS"]["BasicUser"] : planPricesMonthly["ATS"]["BasicUser"]}</b> / month for each additional user</p> */}
                                  </div>
                                </div>
                              </div>
                              <a class="plan-button-bordered-pink" href="#" onClick={() => onClickPricing("Pricing ATS Basic")}>Start now</a>
                              <div class="highlights">
                                <h3>Plan Highlights</h3>
                                <p class="highlights-subtitle">Everything in Free, plus</p>
                                <ul>
                                  {highlights.ATS.Basic.map((highlight, index) => (
                                    <li key={index}>{highlight}</li>
                                  ))}
                                </ul>
                              </div>
                            </AnimationOnScroll>
                          </div>

                          <div className="plan plan-select">
                            <AnimationOnScroll animateIn="animate__flash" animateOnce={true} className="plan-animation">
                              <div className="plan-header">
                                {/* <p className="plan-label">ATS <i class="fa fa-cog fa-spin"></i></p> */}
                                <h2 className="plan-name">Standard </h2>
                                <div class="plan-prices">
                                  <div class="plan-price-wrapper">
                                    <p class="plan-price-wrapper">
                                      <span class="plan-price">
                                        <sup class="plan-price-currency">$</sup>
                                        <span class="plan-price-num">{isYearly ? planPricesYearly["ATS"]["Standard"] : planPricesMonthly["ATS"]["Standard"]}</span>
                                      </span>
                                      <span class="plan-price-duration">/mo {isYearly && 'x 12'}</span>
                                    </p>
                                    {/* <p class="plan-users-num">For first 30 users</p>
                                  <p class="plan-additional-users"><b>${isYearly ? planPricesYearly["ATS"]["StandardUser"] : planPricesMonthly["ATS"]["StandardUser"]}</b> / month for each additional user</p> */}
                                  </div>
                                </div>
                              </div>
                              <a class="plan-button-bordered-pink" href="#" onClick={() => onClickPricing("Pricing ATS Standard")}>Start now</a>
                              <div class="highlights">
                                <h3>Plan Highlights</h3>
                                <p class="highlights-subtitle">Everything in Basic, plus</p>
                                <ul>
                                  {highlights.ATS.Standard.map((highlight, index) => (
                                    <li key={index}>{highlight}</li>
                                  ))}
                                </ul>
                              </div>
                            </AnimationOnScroll>
                          </div>

                          <div className="plan">
                            <AnimationOnScroll animateIn="animate__slideInRight" animateOnce={true} className="plan-animation">
                              <div className="plan-header">
                                {/* <p className="plan-label">ATS <i class="fa fa-cog fa-spin"></i></p> */}
                                <h2 className="plan-name">Enterprise</h2>
                                <div class="plan-prices">
                                  <div class="plan-price-wrapper">
                                    {/* <p class="plan-price-wrapper">
                                      <span class="plan-price">
                                        <sup class="plan-price-currency">$</sup>
                                        <span class="plan-price-num">{isYearly ? planPricesYearly["ATS"]["Enterprise"] : planPricesMonthly["ATS"]["Enterprise"]}</span>
                                      </span>
                                      <span class="plan-price-duration">/mo {isYearly && 'x 12'}</span>
                                    </p> */}
                                    <p class="plan-price-wrapper">
                                      <span class="plan-price">
                                        <span class="plan-price-num far fa-comment-dots">
                                          {/* <svg width="70" height="64" viewBox="0 0 70 64" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M20.7533 50.8641C21.726 50.4148 22.832 50.3383 23.8582 50.6493C27.2353 51.6726 30.9105 52.2372 34.7712 52.2372C52.445 52.2372 65.1989 40.7866 65.1989 28.4008C65.1989 16.0091 52.445 4.56139 34.7712 4.56139C17.0971 4.56139 4.34418 16.0091 4.34659 28.4C4.34659 34.7429 7.55133 40.6962 13.1472 45.1352C14.7487 46.4057 15.237 48.6134 14.3188 50.4321L12.0774 54.871L20.7533 50.8641ZM2.60656 64.0005L10.4323 48.5018C3.99148 43.3925 4.40334e-07 36.2732 4.40334e-07 28.4008C-0.00302297 12.8511 15.5637 0.250488 34.7712 0.250488C53.9787 0.250488 69.5455 12.8511 69.5455 28.4008C69.5455 43.9445 53.9787 56.5481 34.7712 56.5481C30.4834 56.5481 26.38 55.9211 22.5881 54.7721L2.60656 64.0005Z" fill="currentColor"></path></svg> */}
                                        </span>
                                      </span>
                                    </p>
                                    {/* <p class="plan-users-num">For first 30 users</p>
                                  <p class="plan-additional-users"><b>${isYearly ? planPricesYearly["ATS"]["EnterpriseUser"] : planPricesMonthly["ATS"]["EnterpriseUser"]}</b> / month for each additional user</p> */}
                                  </div>
                                </div>
                              </div>
                              <a class="plan-button-bordered-pink" href="#" onClick={() => onClickPricing("Pricing ATS Enterprise")}>Contact us</a>
                              <div class="highlights">
                                <h3>Plan Highlights</h3>
                                <p class="highlights-subtitle">Everything in Standard, plus</p>
                                <ul>
                                  {/* <li>Applicant tracking Platform</li>
                                <li>Training videos*</li>
                                <li>Send SMS to workforce</li>
                                <li>Document generation</li>
                                <li>Job order fulfilment and dispatching Unlimited jobs</li>
                                <li>Payroll reports</li> */}
                                  {highlights.ATS.Enterprise.map((highlight, index) => (
                                    <li key={index}>{highlight}</li>
                                  ))}
                                </ul>
                              </div>
                            </AnimationOnScroll>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <ul className="custom-ul mt-3 pl-3">
                          {/* <li>SMS Messages $0.05 cents per message</li> */}
                          {/* <li>First 1000 Documents included then $0.10 per doc</li> */}
                        </ul>
                      </div>
                    </div>
                  </>}

                  {selectedTab === 1 && <>
                    <span className="d-flex justify-content-center m-3 mb-4 pricing-section-subtitle">
                      The CRM Hub includes
                    </span>
                    <div className="row justify-content-center">
                      <div className="col-md-8">
                        <div className="plans-list">
                          <AnimationOnScroll animateIn="animate__slideInLeft" animateOnce={true}>
                            <div className="plan">
                              <div className="plan-header">
                                {/* <p className="plan-label">ATS <i class="fa fa-cog fa-spin"></i></p> */}
                                <h2 className="plan-name">Jump-Start Your Sales Pipeline</h2>
                                <div class="plan-prices">
                                  <div class="plan-price-wrapper">
                                    <p class="plan-price-wrapper">
                                      <span class="plan-price">
                                        <sup class="plan-price-currency">$</sup>
                                        <span class="plan-price-num">{isYearly ? planPricesYearly["CRM"]["SalesPipeline"] : planPricesMonthly["CRM"]["SalesPipeline"]}</span>
                                      </span>
                                      <span class="plan-price-duration">/user {isYearly && 'x 12'}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <a class="plan-button-bordered-pink" href="#" onClick={() => onClickPricing("Pricing CRM Sales Pipeline")}>Start now</a>
                              <div class="highlights">
                                <h3>Plan Highlights</h3>
                                <ul>
                                  {highlights.CRM.SalesPipeline.map((highlight, index) => (
                                    <li key={index}>{highlight}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </AnimationOnScroll>


                          <div className="plan">
                            <AnimationOnScroll className="plan-animation" animateIn="animate__slideInRight" animateOnce={true}>
                              <div className="plan-header">
                                <h2 className="plan-name">Mobile Sales App</h2>
                                <div class="plan-prices">
                                  <div class="plan-price-wrapper">
                                    <p class="plan-price-wrapper">
                                      <span class="plan-price">
                                        <sup class="plan-price-currency">$</sup>
                                        <span class="plan-price-num">{isYearly ? planPricesYearly["CRM"]["MobileSalesApp"] : planPricesMonthly["CRM"]["MobileSalesApp"]}</span>
                                      </span>
                                      <span class="plan-price-duration">/user {isYearly && 'x 12'}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <a class="plan-button-bordered-pink" href="#" onClick={() => onClickPricing("Pricing CRM Mobile Sales App")}>Start now</a>
                              <div class="highlights">
                                <h3>Plan Highlights</h3>
                                <ul>
                                  {highlights.CRM.MobileSalesApp.map((highlight, index) => (
                                    <li key={index}>{highlight}</li>
                                  ))}
                                </ul>
                              </div>
                            </AnimationOnScroll>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center  mt-3 ml-2">
                      <div className="col-md-8">
                        <ul className="custom-ul">
                          {/* <li>First 1000 Documents included then $0.10 per doc</li> */}
                        </ul>
                      </div>
                    </div>
                  </>}

                  {selectedTab === 2 && <>
                    <span className="d-flex justify-content-center m-3 mb-4 pricing-section-subtitle">
                      The hirebase platform includes
                    </span>
                    <div className='d-flex justify-content-center m-3 mb-4'>
                      <ul className="custom-ul">
                        <li>Payroll Export Sync : $299 per month</li>
                        <li>API Access: Free with subscription</li>
                        <li>Customization and app development: $90/hr</li>
                      </ul>
                    </div>
                    {/* <div class="logo-gallery-container">
                      <img src={logoCFSImg} />
                      <img src={logoGmailImg} className='px-5' />
                      <img src={logoTwilioImg} />
                      <img src={logoOutlookImg} className='px-5' />
                      <img src={logoPaychexImg} />
                      <img src={logoAdpImg} />
                      <img src={logoChatgptImg} className='px-5' />
                    </div> */}

                    <div className="row p-3">
                      <div className="col-md-3 mb-4">
                        <div class="card-company">
                          <img src={logoCFSImg} alt="company" class="avatar w-75" />
                          <span className="company-name">CFS</span>
                          <div class="details">
                            <p>Import and export payroll information to CFS</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 mb-4">
                        <div class="card-company">
                          <img src={logoGmailImg} alt="company" class="avatar" />
                          <span className="company-name">Gmail</span>
                          <div class="details">
                            <p>Connect email communications</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 mb-4">
                        <div class="card-company">
                          <img src={logoTwilioImg} alt="company" class="avatar w-50" />
                          <span className="company-name">Twilio</span>
                          <div class="details">
                            <p>SMS /  Phone integrations</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 mb-4">
                        <div class="card-company">
                          <img src={logoOutlookImg} alt="company" class="avatar" />
                          <span className="company-name">Outlook</span>
                          <div class="details">
                            <p>Connect with all your microsoft products</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 mb-4">
                        <div class="card-company">
                          <img src={logoPaychexImg} alt="company" class="avatar w-50" />
                          <span className="company-name">Paychex</span>
                          <div class="details">
                            <p>Import and export payroll information Paychex</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 mb-4">
                        <div class="card-company">
                          <img src={logoAdpImg} alt="company" class="avatar" />
                          <span className="company-name">ADP</span>
                          <div class="details">
                            <p>Import and export payroll information ADP</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 mb-4">
                        <div class="card-company">
                          <img src={logoChatgptImg} alt="company" class="avatar" />
                          <span className="company-name">ChatGPT</span>
                          <div class="details">
                            <p>Streamline your workflow with ChatGPT</p>
                          </div>
                        </div>
                      </div>

                    </div>


                  </>}

                </div>
              </div>
            </div>
          </div>


          <div className="justify-content-center">
            <div className="row custom-row">
              <div className="col-md-12 p-md-5 ">
                <h1 className="d-flex justify-content-center section1-title mb-5">
                  Compare All Features
                </h1>

                <div class="accordion" id="faq">
                  <div class="card">
                    <div class="card-header" id="faqhead1">
                      <a href="#" class="btn btn-header-link" data-toggle="collapse" data-target="#faq1" aria-expanded="true" aria-controls="faq1"><i class="fa fa-sun fa-spin mr-2"></i>Features</a>
                    </div>

                    <div id="faq1" class="collapse show" aria-labelledby="faqhead1" data-parent="#faq">
                      <div class="card-body">
                        <div class="table-responsive">
                          <table class="table table-striped">
                            <thead>
                              <tr>
                                <th scope="col">Feature</th>
                                <th scope="col">Free</th>
                                <th scope="col">Basic</th>
                                <th scope="col">Standard</th>
                                <th scope="col">Enterprise</th>
                                <th scope="col">Customizations</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                featureArr.map((d) =>
                                  <tr>
                                    {/* <th scope="row">1</th> */}
                                    <td>{d.feature}</td>
                                    <td>
                                      {d.free === "y" &&
                                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                                      }
                                    </td>
                                    <td>
                                      {d.basic === "y" &&
                                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                                      }
                                    </td>
                                    <td>
                                      {d.standard === "y" &&
                                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                                      }
                                    </td>
                                    <td>
                                      {d.enterprise === "y" &&
                                        <i class="fa fa-check-circle" aria-hidden="true"></i>
                                      }
                                    </td>
                                    <td>{d.customizations}</td>
                                  </tr>
                                )
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div class="card">
                    <div class="card-header" id="faqhead2">
                      <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq2"
                        aria-expanded="true" aria-controls="faq2">Tab2</a>
                    </div>

                    <div id="faq2" class="collapse" aria-labelledby="faqhead2" data-parent="#faq">
                      <div class="card-body">
                        Tab 22
                      </div>
                    </div>
                  </div> */}
                </div>



              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row page-container">
        <div className="container bg-light-gray" >
          <SectionDemo
            openModalSignUp={props.openModalSignUp}
          />
        </div>
      </div>
    </>
  )
}

export default Pricing;