import React, { useEffect } from "react";
import SectionDemo from "../section/sectionDemo";

import curveLineAarrowRoundVerticalImg from './../../assets/img/clipart/curve-line-arrow-round-vertical.svg';
import uploadDownloadDocumentsImg from './../../assets/img/home/upload-download-documents.png';
import teamImg from './../../assets/img/page/team.png';

import ReactGA from 'react-ga4';

function AboutUs(props) {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <>
      <div className="row page-container">
        <div className="container" >
          <div className="row custom-row">
            <div className="col-md-12  mt-5 p-md-5 ">
              <h1 className="d-flex justify-content-center section1-title pt-5 pt-md-0">
                About Us
              </h1>
              <img className="d-none d-lg-block" src={curveLineAarrowRoundVerticalImg} alt="no" style={{ position: "absolute", marginTop: "0%", marginLeft: "20%", height: "35%" }} />
              <div className="d-flex justify-content-center pt-2 onboarding-sub-heading"> Hirebase was made to be a one stop shop for your staffing firm. </div>
            </div>
          </div>
          <div className="row custom-row" >
            <div className="col-lg-5 p-md-5">
              <div className="d-flex justify-content-center">
                <img src={uploadDownloadDocumentsImg} className="w-75" alt="not found" />
              </div>
            </div>
            <div className="col-lg-7 p-3 p-md-5 align-self-center">
              <h1 className="section2-title">How it<span className="section1-title-bold"> Started</span></h1>
              {/* <div className="section2-title"><span className="section1-title-bold">Workflow</span></div> */}
              <h3 className="h5 pt-4">
                Hirebase was born out of the pandemic to automate the process of hiring and running a staffing company. One thing that differentiates Hirebase is the team. Our Team is full of artists and builders, we have been building entrprise software for over 2 decades for some of the largest companies in the world.
              </h3>
            </div>
          </div>
          <div className="row  bg-light-gray custom-row pt-5 justify-content-evenly">
            <div className="col-lg-6 p-3 p-md-5 align-self-center">
              <h1 className="section1-title">How it's<span className="section1-title-bold"> Going</span></h1>
              <h3 className="h5 pt-lg-5">
                With The hirebase platform our customers were able to scale and migrate away from IT services and spreadsheets and scale their business. With a single technology platform you can spend more time builing relationships with your employees. We are building HireBase for the long term and wont shy away from building new features with cutting edge technology.
              </h3>
            </div>
            <div className="col-lg-6 p-3 p-md-5">
              <div className="d-flex justify-content-center">
                <img src={teamImg} className="w-75" alt="not found" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container bg-light-gray" >
        <SectionDemo
          openModalSignUp={props.openModalSignUp}
        />
      </div>
    </>
  )
}

export default AboutUs;