import React, { useEffect, useState } from "react";
import './css/section3.css';
import gridDetailsImg from '../../assets/img/home/grid-details.png';
import profileImg from '../../assets/img/home/profile.png';
import messagesAandNotesImg from '../../assets/img/home/messages-and-notes.png';
import feedsImg from '../../assets/img/home/feeds.png';
import dashboardImg from '../../assets/img/home/dashboard.svg';
import jobAndManagementImg from '../../assets/img/home/job-and-management.png';

import CardBox from "../widgets/cardBox";
import onlineApplicationsImg from '../../assets/img/widgets/online-applications.svg';
import documentImg from '../../assets/img/widgets/document.svg';
import manageJobsImg from '../../assets/img/widgets/manage-jobs.svg';
import reduceNoShowsImg from '../../assets/img/widgets/reduce-no-shows.svg';

import glanceFullImg from '../../assets/img/home/glance-full.svg';
import orderManageFullImg from '../../assets/img/home/order-manage-full.svg';
import smsEmailPhoneImg from '../../assets/img/home/sms-email-phone.svg';

import { AnimationOnScroll } from "react-animation-on-scroll";
import './css/sectionFreeDemo.css';

function Section3() {

  // const [showBox, setShowBox] = useState([true, false, false, false]);
  // const [activeImg, setActiveImg] = useState(smsEmailPhoneImg);
  const [showBox, setShowBox] = useState([false, true, false, false]);
  const [activeImg, setActiveImg] = useState(glanceFullImg);

  // const onClickBox = (e) => {
  //   const newState = showBox.map((value, index) => {
  //     if (index === e) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   });
  //   setShowBox(newState);

  //   if (e === 0) {
  //     setActiveImg(messagesAandNotesImg);
  //   } else if (e === 1) {
  //     setActiveImg(profileImg);
  //   } else if (e === 2) {
  //     setActiveImg(feedsImg);
  //   } else if (e === 3) {
  //     setActiveImg(jobAndManagementImg);
  //   }
  // }
  const onClickBox = (e) => {
    const newState = showBox.map((value, index) => {
      if (index === e) {
        return true
      } else {
        return false
      }
    });
    setShowBox(newState);

    if (e === 0) {
      setActiveImg(smsEmailPhoneImg);
    } else if (e === 1) {
      setActiveImg(glanceFullImg);
    } else if (e === 2) {
      setActiveImg(dashboardImg);
    } else if (e === 3) {
      setActiveImg(orderManageFullImg);
    }
  }

  // useEffect(() => {
  //   if (showBox[0] === true) {
  //     const intervalId = setInterval(() => {
  //       if (activeImg === smsEmailPhoneImg) {
  //         setActiveImg(gridDetailsImg);
  //       }
  //       else {
  //         setActiveImg(smsEmailPhoneImg);
  //       }
  //     }, 3000)

  //     return () => clearInterval(intervalId);
  //   }
  // }, [activeImg])

  return (
    <div className="row pt-5 rounded bg-light-gray custom-row">
      <div className="col-lg-5 p-md-5 pb-sm-5">

        <div className="card-box bg-transparent pb-4">
          <span className="card-box-icon pt-2"></span>
          <div className="card-box-content pb-3">
            <h3 className="section3-heading">Simple and Powerful</h3>
          </div>
        </div>

        {/* <AnimationOnScroll animateIn="animate__fadeInLeft">
          <CardBox onClick={(e) => onClickBox(1)} cardActive={showBox[1]} src={onlineApplicationsImg} title="See Everything At a Glance" description="Hirebase is built to keep you on track. With our easy to use interface you can see everything at a glance or see detail with one click." />
          <CardBox onClick={(e) => onClickBox(2)} cardActive={showBox[2]} src={documentImg} title="Dashboards" description="See all actions perfomed / comments and notes at a glance" />
          <CardBox onClick={(e) => onClickBox(3)} cardActive={showBox[3]} src={manageJobsImg} title="Jobs and Order Management" description="Full Dispatch system keep track of orders and employees working" />
          <CardBox onClick={(e) => onClickBox(0)} cardActive={showBox[0]} src={reduceNoShowsImg} title="SMS - Email - Phone" description="Not Just an ATS but a communication and colaboration platform in one." />
        </AnimationOnScroll> */}

        <AnimationOnScroll animateIn="animate__fadeInLeft">
          <CardBox onClick={(e) => onClickBox(1)} cardActive={showBox[1]} src={onlineApplicationsImg} title="See Everything At a Glance" description="Hirebase is built to keep you on track. With our easy to use interface you can see everything at a glance or see detail with one click." />
          <CardBox onClick={(e) => onClickBox(2)} cardActive={showBox[2]} src={documentImg} title="Dashboards" description="See all actions perfomed / comments and notes at a glance" />
          <CardBox onClick={(e) => onClickBox(3)} cardActive={showBox[3]} src={manageJobsImg} title="Jobs and Order Management" description="Full Dispatch system keep track of orders and employees working" />
          <CardBox onClick={(e) => onClickBox(0)} cardActive={showBox[0]} src={reduceNoShowsImg} title="SMS - Email - Phone" description="Not Just an ATS but a communication and colaboration platform in one." />
        </AnimationOnScroll>
      </div>

      <div className="col-lg-7 pl-5 pr-5 pt-2 bg-hb-blue">
        <div className="px-md-5 py-5 py-md-4">
          <AnimationOnScroll animateIn="animate__fadeInRight">
            {
              showBox[0] === true ?
                // <marquee attribute_name="attribute_value" direction="left" scrollamount="10" >
                //   <img className="section3-right-image-100  pb-5" src={gridDetailsImg} alt="no" />
                //   <img className="section3-right-image-100  pb-5" src={activeImg} alt="no" />
                // </marquee>
                // <img className="section3-right-image-100  pb-5" src={images[currentIndex]} alt="no" /> 
                // <AnimationOnScroll animateIn="animate__fadeIn  animate__infinite	infinite" duration={3} initiallyVisible={false} animateOnce={true}>
                //<div className="animate__animated animate__fadeIn  animate__infinite	infinite animate__slower">
                  <img className="section3-right-image-100  px-5" src={activeImg} alt="no" />
                //</div>
                // </AnimationOnScroll>
                :
                <img className="section3-right-image-100  px-md-5" src={activeImg} alt="no" />
            }
          </AnimationOnScroll>
        </div>
      </div>

    </div >
  )
}

export default Section3;