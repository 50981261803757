import React from "react";
import { useHistory } from "react-router";

import './css/sectionOnePlace.css';
import uploadDownloadDocumentsImg from '../../assets/img/home/upload-download-documents.svg';
import { AnimationOnScroll } from "react-animation-on-scroll";
import CustomButton from "../controls/buttons/customButton";

import ReactGA from 'react-ga4';


function SectionOnePlace() {

  const history = useHistory();

  const onClickCollaborate = (e) => {
//GA4 Tracking
    ReactGA.event({
      category: 'On Boarding',
      action: 'On Boarding',
      label: 'On Boarding'
    });

    history.push('/onboarding');
  }

  return (
    <div className="row one-place-bg custom-row">
      <div className="col-lg-6 p-5">
        <AnimationOnScroll animateIn="animate__fadeInLeft">
          <div className="one-place-title pt-5">All Of your <span className="one-place-title-underline">Documents</span> In <br /><span className="one-place-title-bold">one</span> place</div>
          <p className="one-place-message mt-5">Once employees are on-boarded, Hirebase automaticlly generates pre-filled I9’s , W4's and employment agreements. Saving time and money.</p>
          <CustomButton text="Read More" onClick={onClickCollaborate} />
        </AnimationOnScroll>
      </div>
      <div className="col-lg-6">
          <AnimationOnScroll animateIn="animate__fadeInRight">
        <div className="d-flex justify-content-center">
            <img className="shape shape-one mt-0  mb-5 one-place-right-side-image" src={uploadDownloadDocumentsImg} style={{ width: "50%" }} />
            {/* <img className="shape shape-one mt-0  mb-5 one-place-right-side-image" src={uploadDownloadDocumentsImg}  /> */}
        </div>
          </AnimationOnScroll>
      </div>
    </div>
  )
}

export default SectionOnePlace;