import React from "react";
import './css/cardBox.css';
import arrowImg from '../../assets/img/clipart/arrow.svg';

function CardBox(props) {

  return (
    // <div className="card-box ">
    <div className={`card-box hover-pointer ${props.cardActive ? '' : "bg-transparent"} `} onClick={props.onClick}>
      <span className="card-box-icon pt-2"><img src={props.src ? props.src : ''} atr="no" /></span>
      <div className="card-box-content pt-2">
        {/* <h2 className={`card-box-text card-box-title ${props.textColor ? props.textColor : ''} `}>{props.title ? props.title : ''}{props.cardActive ? '':<img src={arrowImg} /> }</h2> */}
        <h2 className={`card-box-text card-box-title ${props.textColor ? props.textColor : ''} `}>{props.title ? props.title : ''}</h2>
        {
          props.cardActive ?
            <p className="card-box-description">{props.description}</p> : ""
        }
      </div>
    </div>
  )
}

export default CardBox;