import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  fullName: "",
  email: "",
  phoneNumber: "",
  companyName: "",
  position: "",
  numberOfEmployees: "",
  numberOfApplicantPerMonth: "",
  subscribe: true
}

export const signUprSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    addFullName: (state, action) => {
      state.fullName = action.payload;
    },
    addEmail: (state, action) => {
      state.email = action.payload;
    },
    addPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload;
    },
    addCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    addPosition: (state, action) => {
      state.position = action.payload;
    },
    addNumberOfEmployees: (state, action) => {
      state.numberOfEmployees = action.payload;
    },
    addNumberOfApplicantPerMonth: (state, action) => {
      state.numberOfApplicantPerMonth = action.payload;
    },
    addSubscribe: (state, action) => {
      state.subscribe = action.payload;
    },
    resetSignUp: () => initialState // Reset state to initial values
  },
})

// Action creators are generated for each case reducer function
export const { addFullName, addEmail, addPhoneNumber, addCompanyName, addPosition, addNumberOfEmployees, addNumberOfApplicantPerMonth, addSubscribe, resetSignUp } = signUprSlice.actions

export default signUprSlice.reducer