

import React from 'react';
import CustomButton from '../controls/buttons/customButton';
import ecosystemImg from './../../assets/img/home/ecosystem.svg';


function SectionEcosystem(props) {
    return (
        <div className="row  bg-light-gray custom-row pt-5">
            <div className="col-lg-6 p-5 align-self-center">
                <h1 className="section1-title">Consolidate your existing <br /><span className="section1-title-bold">Ecosystem</span></h1>
                <h3 className="h5 pt-lg-5">
                    Have an existing workflow that you use already? Have too many applications and looking to consolidate? We are here to help . Hirebase is a extendable and flexible.
                </h3>
                <CustomButton text="Schedule a  demo" onClick={props.openModalSignUp} />
            </div>
            <div className="col-lg-6 p-md-5">
                <div className="d-flex justify-content-center">
                    <img src={ecosystemImg} className="w-75" />
                </div>
            </div>
        </div>
    )
}

export default SectionEcosystem;