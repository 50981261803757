import React, { useRef, useState } from "react";
import './css/sectionTestimonialsNew.css';
import { AnimationOnScroll } from "react-animation-on-scroll";
import payrollAndBillingImg from '../../assets/img/home/payroll-and-billing.svg';
import Carousel, { consts } from 'react-elastic-carousel';

function SectionTestimonialsNew() {
  
  const ref = useRef();
  const [isEnableAutoPlay, setIsEnableAutoPlay] = useState(true);
  
  const items = [
    { id: 1, title: 'Pamela', position: 'Manager', details: 'The best part to me is that all information about an associate is at your fingertips.' },
    { id: 2, title: 'Jose', position: 'Associate', details: 'The best part of the HireBase system is having photos of employees & once everything is input and signed, I love how the system automatically pre fills the W4 and I9 forms' },
    { id: 3, title: 'Ivonne', position: 'Associate', details: 'The layout, color scheme, tabs, for the most part the system is very easy to understand and adapt to.' },
    { id: 4, title: 'Kimberly', position: 'Managing Director', details: 'New start information is very easy to send if employee profiles are fully complete.' },
    { id: 5, title: 'Paula', position: 'Recruiter', details: 'The best part for me is having all docs organized and in front of us rather than going and searching for their file in our file cabinets' },
    { id: 6, title: 'Jose', position: 'Recruiter', details: 'I like how we are able to add Notes and see updates on Associate we were not able to do on google sheets. Also, how there is a picture taken of associates for their profile its a lot easier to remember the associate and create more of a relationship.' }
  ];

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 1 },
    { width: 1200, itemsToShow: 1 }
  ];

  const carouselRef = useRef(null);

  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? 'fa fa-arrow-left' : 'fa fa-arrow-right'
    return (
      // <button onClick={onClick} disabled={isEdge} className="testimonialsNew-button">
      //   {pointer}
      // </button>
      <span onClick={onClick} disabled={isEdge} className="testimonialsNew-button">
        <i className={pointer}></i>
      </span>
    )
  }

  const handleMouseEnter = () => {
    setIsEnableAutoPlay(false);
  };

  const handleMouseLeave = () => {
    setIsEnableAutoPlay(true);
  };


  return (
    // <div className="row custom-row mb-5 testimonialsNew-place-bg">
    //   <div className="col-lg-6 p-5">
    //     <AnimationOnScroll animateIn="animate__fadeInLeft">
    //       <p className="testimonialsNew-title pt-5">Testimonials<br /></p>
    //       <p className="testimonialsNew-sub-title pt-2">What are <span className="testimonialsNew-sub-title-bold">People</span>  Saying<br /> About Hirebase ?</p>
    //       <div className="p-3 p-md-5 p-lg-2 card testimonialsNew-card">
    //         <p>I like how things are represented in reports. The way all documents are organized in front of us is the best part for me, rather than going and searching for their files in our feed.</p>
    //       </div>
    //       <p className="testimonialsNew-sub-title pt-2 mb-0">David Castler</p>
    //       <p className="testimonialsNew-underline">Founder & CEO, Texas</p>
    //     </AnimationOnScroll>
    //   </div>
    //   <div className="col-lg-6 pt-4">
    //     <AnimationOnScroll animateIn="animate__fadeInRight">
    //       <div className="d-flex justify-content-center">
    //         <img className="shape shape-one mt-5 pt-5 one-place-right-side-image" src={payrollAndBillingImg} style={{ width: "50%" }} />
    //         {/* <img className="shape shape-one mt-0  mb-5 one-place-right-side-image" src={uploadDownloadDocumentsImg}  /> */}
    //       </div>
    //     </AnimationOnScroll>
    //   </div>
    // </div>
    <div className="row custom-row mb-5 testimonialsNew-place-bg">
      <div className="col-lg-6 p-5">
        <AnimationOnScroll animateIn="animate__fadeInLeft">
          <p className="testimonialsNew-title pt-5">Testimonials<br /></p>
          <p className="testimonialsNew-sub-title pt-2 pb-4">What are <span className="testimonialsNew-sub-title-bold">People</span>  Saying<br /> About Hirebase ?</p>
          {/* <div className="p-3 p-md-5 p-lg-2 card testimonialsNew-card">
            <p>I like how things are represented in reports. The way all documents are organized in front of us is the best part for me, rather than going and searching for their files in our feed.</p>
          </div> */}

          <Carousel
            itemsToScroll={1}
            enableAutoPlay={isEnableAutoPlay}
            showArrows={true}
            breakPoints={breakPoints}
            autoPlaySpeed={3000}
            ref={carouselRef}
            onNextEnd={(currentItem, pageIndex) => {
              if (isEnableAutoPlay && currentItem.index === 5) {
                setTimeout(() => { carouselRef.current.goTo(0) }, 3000)
              }
            }}
            renderArrow={myArrow}
          >
            {items.map(item => <div className="">
              <div className="p-3 p-md-5 p-lg-2 card testimonialsNew-card" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <p>{item.details}</p>
              </div>
              <p className="testimonialsNew-sub-title pt-2 mb-0">{item.title}</p>
              <p className="testimonialsNew-underline">{item.position}</p>
            </div>
            )}
          </Carousel>
        </AnimationOnScroll>
      </div>
      <div className="col-lg-6 pt-4">
        <AnimationOnScroll animateIn="animate__fadeInRight">
          <div className="d-flex justify-content-center">
            <img className="shape shape-one mt-5 pt-5 one-place-right-side-image" src={payrollAndBillingImg} style={{ width: "65%" }} />
            {/* <img className="shape shape-one mt-0  mb-5 one-place-right-side-image" src={uploadDownloadDocumentsImg}  /> */}
          </div>
        </AnimationOnScroll>
      </div>
    </div>
  )
}

export default SectionTestimonialsNew;