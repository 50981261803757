import { createAsyncThunk, createSlice, nanoid } from '@reduxjs/toolkit'
import { fetchStatus, HirebaseAPI } from '../../../../api/client';
import { isJSON } from '../../../../utilities/utilityFunctions';

const initialState = {
    verifyTrialAccountLink: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    checkSubDomainIsTaken: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    single: {
        status: fetchStatus.IDLE,
        error: null,
        data: undefined,
        refreshed: nanoid()
    },
    creation: {
        status: fetchStatus.IDLE,
        error: null,
        createdResource: undefined
    },
}


export const getVerifyTrialAccountLink = createAsyncThunk('subscription/getVerifyTrialAccountLink', async (subscriptionModel, { rejectWithValue }) => {
    try {
        const response = await HirebaseAPI.post(`Identity/getVerifyTrialAccountLink`, subscriptionModel);
        const data = response.data ? response.data.data : undefined;
        let subscription = undefined;
        if (data && isJSON(data)) {
            subscription = JSON.parse(data);
        }
        return {
            subscription: subscription,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }
});


export const getCheckSubDomainIsTaken = createAsyncThunk('subscription/getCheckSubDomainIsTaken', async (subscriptionModel, { rejectWithValue }) => {
    try {
        const response = await HirebaseAPI.post(`Identity/getCheckSubDomainIsTaken`, subscriptionModel);
        const data = response.data ? response.data.data : undefined;
        // console.log('response.data -->', response.data)
        let company = undefined;
        if (data && isJSON(data)) {
            company = JSON.parse(data);


        }
        return {
            company: company,
            success: response.data.success
        };
    } catch (err) {
        // console.log('response.data --> err.response.data ', err.response.data)
        return rejectWithValue(err.response.data)
    }

});

export const createCompany = createAsyncThunk('subscription/createCompany', async (subscriptionModel, { rejectWithValue }) => {
    try {
        const response = await HirebaseAPI.post(`Identity/createCompany`, subscriptionModel);
        const data = response.data ? response.data.data : undefined;
        let company = undefined;
        if (data && isJSON(data)) {
            company = JSON.parse(data);
        }
        return {
            company: company,
            success: response.data.success
        };
    } catch (err) {
        return rejectWithValue(err.response.data)
    }

});


export const subscriptionSlice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        updateSingleData: (state, _data) => {
            state.single.refreshed = nanoid();
            state.single.data = _data.payload ? Object.assign({}, _data.payload.company) : _data.payload;
        },
        createNewCompany: (state, action) => {
            const { subscriptionId } = action.payload;

            state.single.data = {
                "CompanyName": {
                    "Id": "txtCompanyName",
                    "Data": ""
                },
                "CompanyPhoneNumber": {
                    "Id": "txtCompanyPhoneNumber",
                    "Data": ""
                },
                "CompanyAddress": {
                    "Id": "txtCompanyAddress",
                    "Data": ""
                },
                "CompanyWebsite": {
                    "Id": "txtCompanyWebsite",
                    "Data": ""
                },
                "WorkspaceURL": {
                    "Id": "txtWorkspaceURL",
                    "Data": ""
                },
                "Status": {
                    "Id": "drpStatus",
                    "Data": ""
                },
                "Plan": {
                    "Id": "drpPlan",
                    "Data": ""
                },
                "Email": {
                    "Id": "txtEmail",
                    "Data": ""
                },
                "Password": {
                    "Id": "txtPassword",
                    "Data": ""
                },
                "LocationName": {
                    "Id": "txtLocationName",
                    "Data": ""
                },
                "FirstName": {
                    "Id": "txtFirstName",
                    "Data": ""
                },
                "LastName": {
                    "Id": "txtLastName",
                    "Data": ""
                },
                "subscriptionId": subscriptionId
            };
        },
    },
    extraReducers: (build) => {
        build.addCase(getVerifyTrialAccountLink.pending, (state, action) => {
            state.verifyTrialAccountLink.status = fetchStatus.LOADING;
        }).addCase(getVerifyTrialAccountLink.fulfilled, (state, action) => {
            state.verifyTrialAccountLink.data = action.payload.subscription;
            state.verifyTrialAccountLink.status = fetchStatus.SUCCEEDED;
        }).addCase(getVerifyTrialAccountLink.rejected, (state, action) => {
            state.verifyTrialAccountLink.status = fetchStatus.FAILED;
            state.verifyTrialAccountLink.error = action.payload && action.payload.error;
        }).addCase(getCheckSubDomainIsTaken.pending, (state, action) => {
            state.checkSubDomainIsTaken.status = fetchStatus.LOADING;
        }).addCase(getCheckSubDomainIsTaken.fulfilled, (state, action) => {
            state.checkSubDomainIsTaken.data = action.payload.company;
            state.checkSubDomainIsTaken.status = fetchStatus.SUCCEEDED;
        }).addCase(getCheckSubDomainIsTaken.rejected, (state, action) => {
            state.checkSubDomainIsTaken.status = fetchStatus.FAILED;
            state.checkSubDomainIsTaken.error = action.payload.error;
        }).addCase(createCompany.pending, (state, action) => {
            state.creation.status = fetchStatus.LOADING;
        }).addCase(createCompany.fulfilled, (state, action) => {
            if (action.payload.company) {
                state.single.data = action.payload.company;
                // let _company = {
                //     id: action.payload.company.id,
                //     CompanyName: action.payload.company.CompanyName.Data,
                //     CompanyPhoneNumber: action.payload.company.CompanyPhoneNumber.Data,
                //     Status: action.payload.company.Status.Data
                // };
                // const created = refreshCreated(state.data.records, _company);
                // state.data.records = created.records;
                // state.data.currentPage = created.lastPage;
                // state.data.totalItems += 1;
                state.single.refreshed = nanoid();
            }
            state.creation.status = fetchStatus.SUCCEEDED;
        }).addCase(createCompany.rejected, (state, action) => {
            state.creation.status = fetchStatus.FAILED;
            state.creation.error = action.error.message;
        });
    }
})

export const { updateSingleData, createNewCompany } = subscriptionSlice.actions

export default subscriptionSlice.reducer


export const selectVerifyTrialAccountLinkData = (state) => {
    return state.subscription.verifyTrialAccountLink ? state.subscription.verifyTrialAccountLink.data : undefined;
}
export const selectVerifyTrialAccountLinkStatus = state => state.subscription.verifyTrialAccountLink.status;
export const selectVerifyTrialAccountLinkError = state => state.subscription.verifyTrialAccountLink.error;


export const selectCheckSubDomainIsTakenStatus = state => state.subscription.checkSubDomainIsTaken.status;
export const selectCheckSubDomainIsTaken = state => state.subscription.checkSubDomainIsTaken.data;

export const selectCompanyById = (state) => {
    return state.subscription.single ? state.subscription.single.data : undefined;
}

export const selectCompnayCreationStatus = state => state.subscription.creation.status;
