import React, { useEffect } from "react";
import './emailSubscribe.css';

import { useDispatch, useSelector } from "react-redux";
import { addEmail} from "../signUp/reducerSlices/signUpSlice";

import ReactGA from 'react-ga4';


function EmailSubscribe(props) {
  const dispatch = useDispatch();
  const email = useSelector((state) => state.signUp.email)

  const onEmail = (value) => {
    dispatch(addEmail(value));
  }

  
  // useEffect(() => {
  //   ReactGA.event({
  //     category: 'Email Subscribe',
  //     action: 'Email Subscribe Try Now',
  //     label: 'Email Subscribe Try Now'
  //   });
  // }, []);

  return (
    // <div className="input-group" >
    <div className={`input-group ${props.emailSubscribeClass}`}>
      <input className="new-email-input" type="email" maxLength="100" required placeholder="Enter your email address" value={email} onChange={e => onEmail(e.target.value)} />
      <button className="new-email-bt" onClick={props.onClick}>Try Now</button>
    </div>
  )
}

export default EmailSubscribe;