import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import React, { useState } from 'react'
// import logo from './logo.svg';
import './App.css';
import './assets/css/custom.css';
import './assets/css/custom-animation.css';
// import reactDom from 'react-dom';
// import { useHistory } from 'react-router';
import HomePage from './component/homePage/homePage';
import PrivacyPolicy from './component/pages/privacyPolicy';
import Collaborate from './component/StaffingCompany/collaborate';
import Onboarding from './component/onboarding/onboarding';
import TopNavBar from './component/navbars/topNavBar';
import SignUp from './component/signUp/signUp';
import BackToTop from './component/backToTop/backToTop';
import Footer from './component/footer/footer';
import AboutUs from './component/pages/aboutUs';
import ContactUs from './component/pages/contactUs';
import ReactGA from 'react-ga4';
import Pricing from './component/pricing/pricing';
import Subscription from './component/pages/subscription/subscription';

// GA4
// const TRACKING_ID = "G-NDCGCT5HLX"; // OUR_TRACKING_ID
const TRACKING_ID = "G-RVR23NZQRP"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App(props) {

  const [isOpenSignUp, setIsOpenSignUp] = useState(false);

  const openModalSignUp = () => {
    //Tracking open SignUp page
    ReactGA.event({
      category: 'Sign Up',
      action: 'on Click Sign Up',
      label: 'Open Sign Up'
    });

    setIsOpenSignUp(true);
    document.body.style.overflow = 'hidden';
  }

  const closeModalSignUp = () => {
    setIsOpenSignUp(false);
    document.body.style.overflow = 'unset';
  }

  const Layout = ({ children, openModalSignUp }) => (
    <>
      <TopNavBar openModalSignUp={openModalSignUp} />
      {children}
      <Footer />
    </>
  );

  return (
    <>
      <BackToTop />

      {(isOpenSignUp) ? (<>
        <div className="modal overflow-auto" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-xl " role="document">
            <div className="modal-content ">
              {/* <div className="modal-body"> */}
              <SignUp
                openModal={openModalSignUp}
                closeModal={closeModalSignUp}
              />
              {/* </div> */}
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show">
        </div>
      </>) : <></>}

      {/* <Router>
        <TopNavBar openModalSignUp={openModalSignUp} />
        <Switch>
          <Route exact path="/" component={() => <HomePage openModalSignUp={openModalSignUp} />} />
          <Route exact path="/StaffingCompany" component={() => <Collaborate openModalSignUp={openModalSignUp} />} />
          <Route exact path="/onboarding" component={() => <Onboarding openModalSignUp={openModalSignUp} />} />
          <Route exact path="/privacy-policy" component={() => <PrivacyPolicy openModalSignUp={openModalSignUp} />} />
          <Route exact path="/about-us" component={() => <AboutUs openModalSignUp={openModalSignUp} />} />
          <Route exact path="/contact-us" component={() => <ContactUs openModalSignUp={openModalSignUp} />} />
          <Route exact path="/pricing" component={() => <Pricing openModalSignUp={openModalSignUp} />} />
          <Route exact path="/subscription" component={() => <Subscription />} />
          <Route exact path="/callbackTrialAccount" component={() => <Subscription />} />
          <Route exact path="*" component={() => <HomePage openModalSignUp={openModalSignUp} />} />
        </Switch>
        <Footer />
      </Router> */}

      <Router>
        <Switch>
          <Route exact path="/" component={() => <Layout openModalSignUp={openModalSignUp}><HomePage openModalSignUp={openModalSignUp} /></Layout>} />
          <Route exact path="/StaffingCompany" component={() => <Layout openModalSignUp={openModalSignUp}><Collaborate openModalSignUp={openModalSignUp} /></Layout>} />
          <Route exact path="/onboarding" component={() => <Layout openModalSignUp={openModalSignUp}><Onboarding openModalSignUp={openModalSignUp} /></Layout>} />
          <Route exact path="/privacy-policy" component={() => <Layout openModalSignUp={openModalSignUp}><PrivacyPolicy openModalSignUp={openModalSignUp} /></Layout>} />
          <Route exact path="/about-us" component={() => <Layout openModalSignUp={openModalSignUp}><AboutUs openModalSignUp={openModalSignUp} /></Layout>} />
          <Route exact path="/contact-us" component={() => <Layout openModalSignUp={openModalSignUp}><ContactUs openModalSignUp={openModalSignUp} /></Layout>} />
          <Route exact path="/pricing" component={() => <Layout openModalSignUp={openModalSignUp}><Pricing openModalSignUp={openModalSignUp} /></Layout>} />
          <Route exact path="/callbackTrialAccount" component={Subscription} />
          <Route path="*" component={() => <Layout openModalSignUp={openModalSignUp}><HomePage openModalSignUp={openModalSignUp} /></Layout>} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
